import { Model, Store } from 'store/Base';
import { observable, computed } from 'mobx';

export const TYPE_ROAD = 'road';
export const TYPE_FERRY = 'ferry';
export const TYPES = [TYPE_ROAD, TYPE_FERRY];

export class Transporter extends Model {
    static backendResourceName = 'transporter';

    @observable id = null;
    @observable type = TYPE_FERRY;
    @observable name = '';

    @computed get shortName() {
        if (this.name.toLowerCase().includes('dfds')) {
            return 'dfs'
        } else if (this.name.toLowerCase().includes('cldn')) {
            return 'cldn'
        }

        return ''
    }
}

export class TransporterStore extends Store {
    Model = Transporter;
    static backendResourceName = 'transporter';
}
