import { Casts, Model, Store } from 'store/Base';
import { observable } from 'mobx';
import { TruckingCompanyTariffKmRateStore } from './TruckingCompanyTariffKmRate';

export class TruckingCompanyTariff extends Model {
    static backendResourceName = 'trucking_company_tariff';

    @observable id = null;
    @observable name = null;
    @observable startDate = null;
    @observable endDate = null;
    @observable applyRoadPricing = false;

    relations() {
        return {
            kmRates: TruckingCompanyTariffKmRateStore,
        };
    }

    casts() {
        return {
            startDate: Casts.date,
            endDate: Casts.date,
        };
    }
}

export class TruckingCompanyTariffStore extends Store {
    Model = TruckingCompanyTariff;
    static backendResourceName = 'trucking_company_tariff';
    comparator = (a,b) => {
        if (!a.startDate) {
            return -1
        }
        if (!b.startDate) {
            return 1
        }
        return b.startDate?.toMillis() - a.startDate?.toMillis()
    }
    get lastTariff() {
        return this.models.slice().sort(this.comparator)[0]
    }
}
