import { Model, Store } from 'store/Base';
import { observable } from 'mobx';
import { ServiceSubtypeStore } from './ServiceSubtype';

export class ServiceType extends Model {
    static backendResourceName = 'service_type';

    @observable id = null;
    @observable name = null;
    @observable color = null;

    relations() {
        return {
            serviceSubtypes: ServiceSubtypeStore,
        }
    }
}

export class ServiceTypeStore extends Store {
    Model = ServiceType;
    static backendResourceName = 'service_type';
}
