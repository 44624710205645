import { Casts, Model, Store } from 'store/Base';
import { action, observable, computed } from 'mobx';
import { TripStore } from './Trip';
import { Contact } from './Contact';
import { ServiceStore } from './Service';
import { Terminal } from './Terminal';
import { TruckingCompany } from './TruckingCompany';
import { Location } from './Location';
import { DATETIME_FORMAT } from 'helpers';
import { User } from './User';
import { TransicsPosition } from './TransicsPosition';
import { Trailer } from './Trailer';
import { DriverStore } from './Driver';
import { showNotification, showErrorNotifications } from 'helpers/notification';
import { Message } from './Message';
import ActivityType from "store/enums/ActivityType";
import { api } from 'store/Base';
import { getEtaStringSimple, TransicsETA } from './TransicsETA';
import ExternalDataSource from './enums/ExternalDataSource';
import { UserFlagsStore } from './UserFlags';
import { VirtualizedStore } from './VirtualizedStore';
import { Driver } from 'store/Driver';
export class Truck extends Model {
    static backendResourceName = 'truck';
    static omitFields = ['hasTasks'];

    @observable id = null;
    @observable fleetNumber = '';
    @observable licensePlate = '';
    @observable telematica = '';
    @observable dataSource = null;
    @observable dataReference = '';
    @observable phone = '';
    @observable color = '';
    @observable brandType = '';
    @observable emailHaulier = '';
    @observable fixedEquipment = '';
    @observable registrationCountry = '';
    @observable remarks = '';
    @observable inactive = true;
    @observable manualEta = null;
    @observable hasTasks = false;
    @observable adr = null;
    @observable serviceWarningLevel = null;

    // Annotations.
    @observable currentActivityStatus = null;
    @observable activityStatusFinishedCount = 0;
    currentMessage = null;

    get owned() {
        if (this.truckingCompany == null || this.truckingCompany.id == null) {
            return false;
        };
        return !this.truckingCompany?.subcontractor;
    }

    @action
    setCurrentMessage(message) {
        this.currentMessage = message;
    }

    @action
    setChatTextMessage(text) {
        if (this.currentMessage) {
            this.currentMessage.text = text;
        } else {
            this.currentMessage = new Message(
                { text: text },
                { relations: ['truck'] }
            )
        }
    }

    fetchSelfBillableActivities(notInvoiceable) {
        let data = { 'notInvoiceable': notInvoiceable ?? false }

        return this.wrapPendingRequestCount(
            this.api.get(this.url + 'self_billable_activities/', data)
        );
    }

    @computed get identificator() {
        return this.fleetNumber;
    }

    @computed get tagLabel() {
        return `T${this.identificator}`;
    }

    @computed get editUrl() {
        return `/assets/truck/${this.id}/edit`;
    }

    @computed get getColor() {
        if (this.id != null && this.inactive) {
            return "var(--red-100)";
        } else if (this.id != null) {
            return "var(--blue-50)"
        }
        return null;
    }

    @computed get colorBorder() {
        return this.truckingCompany?.colorCompany;
    }

    @computed get ownerName() {
        return this.truckingCompany?.name;
    }

    @computed get isConnectedToTransics() {
        return this.dataSource === ExternalDataSource.TRANSICS &&
            this.dataReference != null && this.dataReference !== '';
    }

    @computed get currentLocation() {
        let location = null;

        if (this.isConnectedToTransics && this.currentPosition?.id != null) {
            // [TODO] maybe should be switch toLocation function with geocode.
            location = new Location(this.currentPosition.toLocationClosest());
        } else if (this.location != null && this.location.id != null) {
            location = this.location;
        }
        return location;
    }

    @computed get currentLocationString() {
        let location = this.currentLocation;

        if (location != null && location.city !== '') {
            return `${location.city}, ${location.country}`
        } else {
            return t(`capacity.fields.unknown.label`)
        }
    }

    @computed get currentLocationInformation() {
        if (this.isConnectedToTransics && this.currentPosition?.id != null) {
            return this.currentPosition.toStringExtended();
        }

        return t('general.noExtraInformationAvailable');
    }

    @computed get hasEta() {
        return true;
    }

    @computed get canEditEta() {
        return !this.isConnectedToTransics;
    }

    @computed get etaString() {
        if (this.isConnectedToTransics && this.currentEta?.id != null) {
            return this.currentEta.stringRestEtaSimple
        } else if (this.manualEta != null) {
            return getEtaStringSimple(this.manualEta);
        } else {
            return t('general.na');
        }
    }

    @computed get etaInformation() {
        if (this.isConnectedToTransics && this.currentEta?.id != null) {
            return this.currentEta.stringExtended
        } else if (this.manualEta != null) {
            return this.manualEta.toFormat(DATETIME_FORMAT);
        } else {
            return t('general.noExtraInformationAvailable');
        }
    }

    @computed get driver() {
        return this.drivers?.length > 0 && this.drivers.at(0);
    }

    toggleActive() {
        this.inactive = !this.inactive;
    }


    sendActivityToCustomer(emailObject, tripActivities) {
        let arrayActivities = [];
        emailObject.activities.map((act) => (
            arrayActivities.push(act.toJS())
        ));

        if (emailObject !== undefined) {
            return this.api.post(`activity/send_activity_to_customer/`, {
                to: emailObject.recipients,
                subject: emailObject.subject,
                content: arrayActivities,
                currency: emailObject.currency,
                tripActivities: tripActivities,
            })
        }
    }

    activate() {
        if (this.location.id > 0) {
            this.save({
                onlyChanges: true,
                data: {
                    inactive: false,
                },
            }).then(()=>{
                showNotification(t(`truck.edit.activate.success`));
                this.inactive = false; // Change value on model only on successful activation, so the buttons wont flicker for the user
            }).catch((err) => {
                showErrorNotifications(err.response.data.errors);
            });
        } else {
            showNotification(t(`truck.edit.locationTruck`));
        }
    }

    deactivate() {
        if (!(this?.attachedTrailer?.id > 0)) {
            this.save({
                data: {
                    inactive: true,
                },
                fields: ['inactive'],
            }).then(()=>{
                showNotification(t(`truck.edit.deactivate.success`));
                this.inactive = true; // Change value on model only on successful deactivation, so the buttons wont flicker for the user
            }).catch((err)=>{
                showErrorNotifications(err.response.data.errors);
            });
        } else {
            showNotification(t(`truck.edit.dropTrailer`));
        }
    }

    saveDriverWeekendPause(selectedOption) {
        if(!this.driver){
            this.drivers.add((new Driver({name: `Unknown ${this.fleetNumber}`})).toJS());
        }
        this.driver.setInput('weekendPause', selectedOption)
        this.save({ onlyChanges: true, relations: ['drivers'] })
    }

    async splitTripYardDropPickUp(location, activity, trip) {
        await this.createYardTrips(location, activity, trip, ActivityType.YARD_DROP)
    }

    async createYardTrips(location, activity, trip, type) {
        let locationObject = location;

        if (location?.id == null) {
            let locationObject = new Location({
                ...location.toJS(),
                point: JSON.stringify(location.point),
            });

            await locationObject.save();
        }

        await api.post(
            '/truck/create-split-trip/',
            { location: locationObject.id, type, truck: this.id, activity: activity.id, trip: trip.id }
        ).then(res => {
            console.log(res)
        })
    }

    async getByFleetNumber(fleetNumber) {
        const truckStore = new TruckStore({
            params: {
                '.fleet_number': fleetNumber,
            },
            relations: this.__activeRelations,
        });

        await truckStore.fetch();
        if (truckStore.models.length > 0) {
            return truckStore.models[0];
        } else {
            return null
        }
    }

    hasWarnings() {
        return false;
    }

    casts() {
        return {
            manualEta: Casts.datetime,
        };
    }

    relations() {
        return {
            assignedUser: User,
            contract: Contact,
            trips: TripStore,
            trucks: TruckStore,
            terminal: Terminal,
            truckingCompany: TruckingCompany,
            location: Location,
            currentPosition: TransicsPosition,
            currentEta: TransicsETA,
            attachedTrailer: Trailer,
            drivers: DriverStore,
            userFlags: UserFlagsStore,
            services: ServiceStore,
            recurrentServices: ServiceStore,
            nonRecurrentServices: ServiceStore,
        };
    }
}

export class TruckStore extends Store {
    Model = Truck;
    static backendResourceName = 'truck';
}

export class VirtualizedTruckStore extends VirtualizedStore {
    Model = Truck;
    static backendResourceName = 'truck';
}
