import { Model, Store, Casts } from 'store/Base';
import { observable, computed, action } from 'mobx';
import { Customer } from './Customer';
import { Job, JobStore } from './Job';
import { Activity } from './Activity';
import { TripStore } from './Trip';
import { DocumentStore } from './Document';
import OrderStatus from './enums/OrderStatus';
import { uniqBy, sumBy } from 'lodash';
import { User } from './User';
import { InvoiceLineStore } from './InvoiceLine';
import { formatMoneyEuro, formatMoneyPound } from 'helpers';
import OrderSubcontractStatus from './enums/OrderSubcontractStatus';
import { OrderSubcontractStore } from './OrderSubcontract';
import { showErrorNotifications, showSaveNotification } from 'helpers/notification';
import { ContactStore } from './Contact';
import { TYPE_CUSTOMS, TYPE_UPDATE } from 'store/enums/ContactTypes';

export const UNIT_FTL = 'ftl';
export const UNIT_LTL = 'ltl';
export const UNIT_OTHER = 'other';
export const UNITS = [UNIT_FTL, UNIT_LTL, UNIT_OTHER];

export const CURRENCY_EURO = 'euro';
export const CURRENCY_POUND = 'pound';
export const CURRENCIES = [CURRENCY_EURO, CURRENCY_POUND];

export const PACKAGING_TYPE_BIG_BAGS = 'big bags';
export const PACKAGING_TYPE_BALES = 'bales';
export const PACKAGING_TYPE_BILLETS = 'billets';
export const PACKAGING_TYPE_BUNDLES = 'bundles';
export const PACKAGING_TYPE_COLLI = 'colli';
export const PACKAGING_TYPE_COILS = 'coils';
export const PACKAGING_TYPE_CARTONS = 'cartons';
export const PACKAGING_TYPE_DRUMS = 'drums';
export const PACKAGING_TYPE_EUROPALLETS = 'europallets';
export const PACKAGING_TYPE_PALLETS = 'pallets';
export const PACKAGING_TYPE_REELS = 'reels';
export const PACKAGING_TYPE_SLABS = 'slabs';
export const PACKAGING_TYPE_LOTS = 'lots';
export const PACKAGING_TYPE_LDM = 'ldm';
export const PACKAGING_TYPE_FTL = 'ftl';
export const PACKAGING_TYPES = [PACKAGING_TYPE_FTL, PACKAGING_TYPE_BIG_BAGS, PACKAGING_TYPE_BALES, PACKAGING_TYPE_BILLETS, PACKAGING_TYPE_BUNDLES, PACKAGING_TYPE_COLLI, PACKAGING_TYPE_COILS, PACKAGING_TYPE_CARTONS, PACKAGING_TYPE_DRUMS, PACKAGING_TYPE_EUROPALLETS, PACKAGING_TYPE_PALLETS, PACKAGING_TYPE_REELS, PACKAGING_TYPE_SLABS, PACKAGING_TYPE_LOTS, PACKAGING_TYPE_LDM];

export const CURRENCY_FLAG = {
    euro: 'eu',
    pound: 'gb',
};

export class Order extends Model {
    static backendResourceName = 'order';

    @observable id = null;
    @observable orderNumber = '';
    @observable status = OrderStatus.NEW;
    @observable unit = UNIT_FTL;
    @observable invoiceReference = '';
    @observable salesPrice = '';
    @observable createdAt = null;
    @observable updatedAt = null;
    @observable remarks = '';
    @observable packagingAmount = '';
    @observable packagingType = null;
    @observable weight = null;
    @observable currency = CURRENCY_EURO;

    // [TODO] change name to adr
    @observable unNumber = false;

    @observable fuelSurcharge = false;
    @observable goodsDescription = '';
    @observable subcontracted = false;
    @observable hasProofOfDelivery = false;
    @observable firstTrailerNumber = '';
    @observable hasTasks = false;
    @observable notes = '';

    relations() {
        return {
            customer: Customer,
            assignedUser: User,
            createdBy: User,
            jobs: JobStore,
            trips: TripStore,
            firstJob: Job,
            lastJob: Job,
            firstActivity: Activity,
            lastActivity: Activity,
            documents: DocumentStore,
            invoicedIn: InvoiceLineStore,
            subcontracts: OrderSubcontractStore,
            orderContactUpdate: ContactStore,
            orderContactCustoms: ContactStore,
        };
    }

    casts() {
        return {
            createdAt: Casts.datetime,
            updatedAt: Casts.datetime,
        };
    }

    @computed get tagLabel() {
        return `O${this.orderNumber}`;
    }

    @computed get editUrl() {
        return `/orders/${this.id}/edit`;
    }

    @computed get isPlanned() {
        return [
            OrderStatus.PLANNED,
            OrderStatus.IN_PROGRESS,
            OrderStatus.COMPLETED,
            OrderStatus.CANCELED
        ].includes(this.status);
    }

    @computed get formattedSalesPrice() {
        if (this.currency === CURRENCY_EURO) {
            return formatMoneyEuro(this.salesPrice);
        } else if (this.currency === CURRENCY_POUND) {
            return formatMoneyPound(this.salesPrice);
        }

        throw new Error(`Unknown currency: ${this.currency}`);
    }

    @computed get salesPriceValue() {
        var value = parseFloat(this.salesPrice);

        if (isNaN(value)) {
          return 0;
        }

        return value;
    }

    @computed get statusColorOverview() {
        switch (this.status) {
            case OrderStatus.ACCEPTED:
                return 'var(--blue-300)'
            case OrderStatus.PLANNED:
                return 'var(--gray-300)'
            case OrderStatus.IN_PROGRESS:
                return 'var(--orange-300)'
            case OrderStatus.COMPLETED:
                return 'var(--green-300)'
            case OrderStatus.CANCELED:
                return 'var(--red-300)'

            default:
                return 'var(--gray-100)';
        }
    }

    @computed get statusColorSemantic() {
        switch (this.status) {
            case OrderStatus.ACCEPTED:
                return 'blue'
            case OrderStatus.PLANNED:
                return 'grey'
            case OrderStatus.IN_PROGRESS:
                return 'orange'
            case OrderStatus.COMPLETED:
                return 'green'
            case OrderStatus.CANCELED:
                return 'red'
            default:
                return 'grey';
        }
    }

    @computed
    get getInvoice() {
        if (this.invoicedIn?.models.at(0)?.invoice) {
            return this.invoicedIn.models.at(0).invoice;
        }

        return null;
    }

    @computed
    get checkInvoiceStatusSent() {
        if (this.invoicedIn?.models.at(0)?.invoice) {
            return this.invoicedIn?.models.at(0)?.invoice.status === 'sent' || this.invoicedIn?.models.at(0)?.invoice.status === 'paid'
        }

        return false;
    }

    @computed
    get activeSubcontract() {
        const activeSubcontracts = this.subcontracts.filter(subcontract =>
            [OrderSubcontractStatus.DRAFT, OrderSubcontractStatus.SENT].includes(subcontract.status));

        if (activeSubcontracts.length === 0) {
            return null;
        }

        return activeSubcontracts[0];
    }

    @computed
    get hasDraftInvoice() {
        const invoiceDrafted = this.invoicedIn.models.find(obj => {
            return obj.invoice.status === 'drafted';
        });

        if (invoiceDrafted === undefined) {
            return null;
        }

        return invoiceDrafted;
    }

    isSameCurrency = (el, index, arr) => {
        if (index === 0) {
            return true;
        } else {
            return (el.invoice.currency === arr[index - 1].invoice.currency);
        }
    }

    @computed
    get invoicedFormattedTotalPrice() {
        let sameCurrency = this.invoicedIn.models.every(this.isSameCurrency);

        if (!sameCurrency) {
            return false
        }
        const sum = sumBy(this.invoicedIn.models, 'total');

        if (this.currency === CURRENCY_EURO) {
            return formatMoneyEuro(sum);
        } else if (this.currency === CURRENCY_POUND) {
            return formatMoneyPound(sum);
        }
    }

    @action
    cancel() {
        if (this?.orderNumber === '') {
            console.error('order number is null')
        } else {
            const currentStatus = this.status;
            this.status = OrderStatus.CANCELED;

            this.save({
                fields: ['status']
            })
            .then(showSaveNotification)
            .catch((err) => {
                this.status = currentStatus;
                showErrorNotifications(err.response.data.errors);
            });
        }
    }

    @action
    reactivate() {
        if (this?.orderNumber === '') {
            console.error('order number is null')
        } else {
            const currentStatus = this.status;
            this.status = OrderStatus.NEW;

            this.save({
                fields: ['status']
            })
            .then(showSaveNotification)
            .catch((err) => {
                this.status = currentStatus;
                showErrorNotifications(err.response.data.errors);
            });
        }
    }

    checkVatRules() {
        return this.wrapPendingRequestCount(
            this.api.get(this.url + 'check_vat/')
        );
    }

    sendLimitReachedEmail = async (creditLimitAmount, creditUsed, calculatedCreditUsed) => {
        this.api.post(`${this.url}send_limit_reached_email/`, { credit_limit: creditLimitAmount, credit_used: creditUsed, calculated_credit_used: calculatedCreditUsed })
    }

    async getByOrderNumber(orderNumber) {
        const orderStore = new OrderStore({
            params: {
                '.order_number': orderNumber,
            },
            relations: this.__activeRelations,
        });

        await orderStore.fetch();
        if (orderStore.models.length > 0) {
            return orderStore.models[0];
        } else {
            return null
        }
    }

    async _getRecipientsOfType(type) {
        if (type === TYPE_UPDATE) {
            return this.orderContactUpdate ? this.orderContactUpdate.map(c => c.emailAddress) : [];
        }
        if (type === TYPE_CUSTOMS) {
            return this.orderContactCustoms ? this.orderContactCustoms.map(c => c.emailAddress) : [];
        }

        return this.customer ? this.customer.getRecipients([type]) : [];
    }

    async getRecipients(typePriorityList) {
        let result = [];
        let i = 0

        while (i < typePriorityList.length) {
            var receipients = await this._getRecipientsOfType(typePriorityList[i]);
            result.push(...receipients);
            i++;
        }

        return result;
    }

    getActivities = (tripType) =>{
        let result = this?.trips?.models;
        if(tripType){
            result = result.filter((trip)=>trip.type===tripType)
        }
        return result.reduce((acc, currVal)=> acc.concat(currVal?.activities),[])

    }

    getBookings = () => {
        let bookingsList = this.getActivities().reduce((acc, currVal)=> currVal.bookings && acc.concat(currVal?.bookings),[])
        return uniqBy(bookingsList, booking => booking?.id);
    }

    getTrucksLicensePlate() {
        try {
            let arrTruckLicensePlate = [];
            uniqBy(this.trips.models, trip => trip.truck.licensePlate).map(trip => {
                let truck = trip.truck.licensePlate;
                trip.m2mActivities.map((relActivity) => {
                    if (truck !== '' && ['load', 'unload'].includes(relActivity.activity.type)) {
                        if (!arrTruckLicensePlate.includes(relActivity.activity.type + ': ' + truck)) {
                            arrTruckLicensePlate.push(relActivity.activity.type + ': ' + truck)
                        }
                    }
                    return ''
                })
                return ''
            })
            return arrTruckLicensePlate;
        } catch (error) {
            return ''
        }
    }
}

export class OrderStore extends Store {
    Model = Order;
    static backendResourceName = 'order';
}
