import { Model, Store } from 'store/Base';
import { computed, observable } from 'mobx';
import { DocumentStore } from './DocumentCustomer';
import { ContactStore } from './Contact';
import { CustomerLocationStore } from './CustomerLocation';

export class Customer extends Model {
    static backendResourceName = 'customer';

    @observable id = null;
    @observable name = '';
    @observable reference = '';
    @observable phone = '';
    @observable emailAddress = ''
    @observable chamberOfCommerce = '';
    @observable vatCode = '';
    @observable iban = '';
    @observable phone = '';
    @observable emailFromName = '';
    @observable emailFromAddress = '';
    @observable emailFromNameDocumentsInvoices = '';
    @observable emailFromAddressDocumentsInvoices = '';
    @observable paymentTerm = 60;
    @observable creditLimitAmount = 1000000; // in cents
    @observable creditLimitExpiryDate = null;
    @observable visitingAddress = '';
    @observable visitingNumber = '';
    @observable visitingZipCode = '';
    @observable visitingCity = '';
    @observable visitingCountry = '';

    @observable invoicingAddress = '';
    @observable invoicingNumber = '';
    @observable invoicingZipCode = '';
    @observable invoicingCity = '';
    @observable invoicingCountry = '';
    @observable contactType = null;
    @observable emailAddressUpdate ='';
    @observable debtorNumber = '';


    @observable createdAt = null;
    @observable updatedAt = null;

    @observable creditLimitUsed = 0;

    @computed get currentCreditLeft() {
        return this.creditLimitAmount - this.creditLimitUsed;
    }


    relations() {
        return {
            documents: DocumentStore,
            contacts: ContactStore,
            locations: CustomerLocationStore,
        };
    }

    async getRecipients(typePriorityList) {
        const contactStore = new ContactStore({
            params: {
                '.email_address:not': '',
                '.customer.id': this.id
            },
        });

        let result = [];
        let i = 0

        while (i < typePriorityList.length) {
            contactStore.params['.type'] = typePriorityList[i];
            await contactStore.fetch();
            result = contactStore.map(c => c.emailAddress);
            if (result && result.length > 0) {
                break;
            }
            i++;
        }

        return result;
    }

}

export class CustomerStore extends Store {
    Model = Customer;
    static backendResourceName = 'customer';
}
