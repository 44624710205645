import { Component as BaseComponent } from 'react';
import axios from 'axios';


export default class Component extends BaseComponent {
    isUnmounted = false;
    requestCancelTokens = [];
    registerCancelToken = () => {
        return new axios.CancelToken(cancelToken => {
            // Found a beautiful case where requests just started as soon
            // as the component was unmounted. This was the case for
            // calculateRoute which is also the slowest request...
            // This happened in setup -> p.then -> fetchFullRoute
            if (!this.isUnmounted) {
                this.requestCancelTokens.push(cancelToken);
            } else {
                cancelToken();
            }
        });
    }

    cancelTokenRequestOptions = () => {
        return { registerCancelToken: this.registerCancelToken };
    }

    componentWillUnmount() {
        this.isUnmounted = true
        this.requestCancelTokens.forEach(cancelToken => cancelToken());
        this.requestCancelTokens = [];
    }
}
