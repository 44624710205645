import { Model, Store, Casts } from 'store/Base';
import { observable } from 'mobx';
import { Route } from './Route';
import { MONDAY, weekdayToNumber } from './enums/Weekday';
import { DATE_FORMAT_SHORT, DATE_FORMAT, TIME_FORMAT } from 'helpers';
import { DateTime } from 'luxon';
import { fetchRouteDistances } from 'services/LocationService';
import _ from 'lodash';
import { TERMINAL_DROP } from './enums/ActivityType';
export class Schedule extends Model {
    static backendResourceName = 'schedule';

    @observable id = null;
    @observable weekday = MONDAY;
    @observable cutOff = DateTime.now().toUTC().startOf('day');
    @observable departure = DateTime.now().toUTC().startOf('day');
    @observable arrival = DateTime.now().toUTC().startOf('day');
    @observable release = DateTime.now().toUTC().startOf('day');
    @observable fullUnitPrice = null;
    @observable emptyUnitPrice = null;
    @observable bafSurcharge = null;
    @observable extraSurcharge = null;


    relations() {
        return {
            route: Route,
        };
    }

    casts() {
        return {
            cutOff: Casts.datetimeUTC,
            departure: Casts.datetimeUTC,
            arrival: Casts.datetimeUTC,
            release: Casts.datetimeUTC,
        };
    }

    toOverviewString() {
        return `${this.weekday?.substring(0, 3)} ${this.departure?.toFormat(TIME_FORMAT)}`;
    }

    toBookingDate(startDate) {
        if (startDate == null) {
            startDate = DateTime.now();
        }

        const dayINeed = weekdayToNumber(this.weekday);
        const today = startDate?.weekday ?? 0;

        let daysToAdd = 0;
        if (dayINeed < today) {
            daysToAdd = 7 - today + dayINeed;
        } else {
            daysToAdd = dayINeed - today;
        }

        return startDate.plus({ days: daysToAdd })
    }

    toBookingString(startDate) {
        return `${this.weekday?.substring(0, 3)} ${this.toBookingDate(startDate)?.toFormat(DATE_FORMAT_SHORT)} ${this.departure?.toFormat(TIME_FORMAT)}`
    }

    toBookingDepartureDate(startDate) {
        var date = this.toBookingDate(startDate);

        return date?.plus({ hours: this.departure?.hour, minutes: this.departure?.minute });
    }

    toBookingArrivalDate(startDate) {
        var date = this.toBookingDate(startDate);

        if (this.arrival?.hour < this.departure?.hour) {
            date = date?.plus({ days: 1 })
        }

        return date?.plus({ hours: this.arrival?.hour, minutes: this.arrival?.minute });
    }

    async totalTripCost(booking) {
        const tripInfo = await this.getScheduleTripInformation(booking);
        return tripInfo.costSum;
    }

    async getScheduleTripInformation(booking) {
        const ferryRoute = this.route;
        const activities = booking.getTrailerTrip()?.activities;

        const distance = await this.getDistancesInformation(booking)

        // Cost price is calculated based on the departure date
        const startDate = activities?.at(0)?.orderedArrivalDatetimeFrom;
        const departureDate = this.toBookingDate(startDate);

        const routeCost = await this.getRouteCost(departureDate, distance);

        const costFerry = this.ferryCost(booking.trailerEmpty);
        const costSum = routeCost.costFrom + costFerry + routeCost.costTo;

        return { ferryRoute, distance, routeCost, costFerry, costSum }

    }

    async getDistancesInformation(booking) {
        const tripActivities = booking.getTrailerTrip()?.getActivitiesSorted();

        // Instead of first and last activity, first activity before from terminal and first after the to terminal should be used. 
        // Using first and last activity calculates direct route which won't be used by the truck as he has stops in between
        // As the rest of the trip is const factor we can ignore it
        const terminalFromIndex = tripActivities.findIndex(tripActivity => tripActivity.activity.type === TERMINAL_DROP)
        const locationFrom = tripActivities.at(terminalFromIndex - 1).activity.location;
        const locationTo = tripActivities.at(terminalFromIndex + 2).activity.location;

        const route = [[locationFrom, this.route.fromTerminal.location], [this.route.toTerminal.location, locationTo]];
        const distances = await fetchRouteDistances(route);
        const distanceFromYard = distances[0] ?? 0;
        const distanceToYard = distances[distances.length - 1] ?? 0;

        return { distances, distanceFromYard, distanceToYard, locationFrom, locationTo }
    }

    async getRouteCost(date, distanceInformation) {
        const memoizedRouteCostToDeparture = _.memoize((...args) => this.routeCostToDeparture(...args));
        const memoizedRouteCostFromArrival = _.memoize((...args) => this.routeCostFromArrival(...args));

        const unitCostToDeparture = await memoizedRouteCostToDeparture(date, this.route.fromTerminal.costArea);
        const unitCostFromArrival = await memoizedRouteCostFromArrival(date, this.route.toTerminal.costArea);

        const costFrom = unitCostFromArrival?.unitCost ? (distanceInformation.distanceToYard * unitCostFromArrival?.unitCost) : null;
        const costTo = unitCostToDeparture?.unitCost ? (distanceInformation.distanceFromYard * unitCostToDeparture?.unitCost) : null;

        return { costFrom, costTo, unitCostToDeparture, unitCostFromArrival }
    }

    ferryCostCalculation(trailerEmpty) {
        let result = `€ ${(this.bafSurcharge / 100).toFixed(2)} + € ${(this.extraSurcharge / 100).toFixed(2)}`;
        if (trailerEmpty) {
            result += ` + € ${(this.emptyUnitPrice / 100).toFixed(2)}`;
        } else {
            result += ` + € ${(this.fullUnitPrice / 100).toFixed(2)}`;
        }

        return result;
    }

    ferryCost(trailerEmpty) {
        let costFerry = this.bafSurcharge + this.extraSurcharge;
        if (trailerEmpty) {
            costFerry = costFerry + this.emptyUnitPrice;

        } else {
            costFerry = costFerry + this.fullUnitPrice;
        }

        return costFerry / 100;
    }

    routeCostToDeparture(departureDate, region) {
        let data = { 'departureDate': departureDate.toFormat(DATE_FORMAT), 'region': region }
        let unitCost = this.wrapPendingRequestCount(
            this.api.get(this.url + 'check_unit_cost_to_departure/', data)
        );
        return unitCost;
    }

    routeCostFromArrival(arrivalDate, region) {
        let data = { 'arrivalDate': arrivalDate.toFormat(DATE_FORMAT), 'region': region }
        let unitCost = this.wrapPendingRequestCount(
            this.api.get(this.url + 'check_unit_cost_from_arrival/', data)
        );
        return unitCost;
    }

    isValidFor(date) {
        const scheduleDate = this.toBookingDate(date);
        if (this.route.validTo == null) {
            return scheduleDate > this.route.validFrom;
        } else {
            return scheduleDate > this.route.validFrom && scheduleDate < this.route.validTo;
        }
    }
}

export class ScheduleStore extends Store {
    Model = Schedule;
    static backendResourceName = 'schedule';
}
