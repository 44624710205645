import { Model, Store } from 'store/Base';
import { observable } from 'mobx';
import { TripActivity } from 'store/TripActivities';
import { PurchaseInvoiceSelfBill } from './PurchaseInvoiceSelfBill';
import { PurchaseInvoiceSelfBillTripActivityLineCorrectionStore } from './PurchaseInvoiceSelfBillTripActivityLineCorrection';

export class PurchaseInvoiceSelfBillTripActivityLine extends Model {
    static backendResourceName = 'purchase_invoice_self_bill_trip_activity_line';

    @observable id = null;
    @observable description = '';
    @observable amount = 0;

    relations() {
        return {
            purchaseInvoiceSelfBill: PurchaseInvoiceSelfBill,
            tripActivity: TripActivity,
            corrections: PurchaseInvoiceSelfBillTripActivityLineCorrectionStore,
        };
    }
}

export class PurchaseInvoiceSelfBillTripActivityLineStore extends Store {
    Model = PurchaseInvoiceSelfBillTripActivityLine;
    static backendResourceName = 'purchase_invoice_self_bill_trip_activity_line';
}
