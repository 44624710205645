import { Model, Store } from 'store/Base';
import { observable } from 'mobx';
import { Booking } from 'store/Booking';
import { PurchaseInvoiceTerminalTransporter as PurchaseInvoiceTerminalTransporterx } from 'store/PurchaseInvoiceTerminalTransporter';

export class PurchaseInvoiceTerminalTransporterBookingLine extends Model {
    static backendResourceName = 'purchase_invoice_terminal_transporter_booking_line';

    @observable id = null;
    @observable description = '';
    @observable amount = 0;

    relations() {
        return {
            booking: Booking,
            purchaseInvoiceTerminalTransporter: PurchaseInvoiceTerminalTransporterx,
        }
    }
}

export class PurchaseInvoiceTerminalTransporterBookingLineStore extends Store {
    Model = PurchaseInvoiceTerminalTransporterBookingLine;
    static backendResourceName = 'purchase_invoice_terminal_transporter_booking_line';
}
