import { Model, Store } from 'store/Base';
import { observable } from 'mobx';
import { Truck } from './Truck';

export class Driver extends Model {
    static backendResourceName = 'driver';

    @observable id = null;
    @observable name = '';
    @observable phoneNumber = '';
    @observable weekendPause = '';

    relations() {
        return {
            truck: Truck,
        }
    }
}

export class DriverStore extends Store {
    Model = Driver;
    static backendResourceName = 'driver';
}
