import { Casts, Model, Store } from 'store/Base';
import { observable } from 'mobx';
import { TerminalStore } from './Terminal';
import { DocumentStore } from './Document';
import { RouteStore } from './Route';
import { CURRENCY_EURO } from './enums/InvoiceCurrency';

export class TerminalTransporter extends Model {
    static backendResourceName = 'terminal_transporter';

    @observable id = null;
    @observable name = '';
    @observable emailAddress = '';
    @observable phone = '';
    @observable address = '';
    @observable zipCode = '';
    @observable country = '';

    @observable iban = '';
    @observable swiftBic = '';
    @observable chamberOfCommerce  = '';
    @observable vatCode  = '';
    @observable contractExpiryDate = null;
    @observable creditorNumber = null;
    @observable contact;
    @observable defaultCurrency = CURRENCY_EURO

    relations() {
        return {
            routes: RouteStore,
            terminals: TerminalStore,
            documents: DocumentStore,
        };
    }

    casts() {
        return {
            contractExpiryDate: Casts.datetime,
        };
    }
}

export class TerminalTransporterStore extends Store {
    Model = TerminalTransporter;
    static backendResourceName = 'terminal_transporter';
}
