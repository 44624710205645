import { Model, Store } from 'store/Base';
import { computed, observable } from 'mobx';
import { TerminalTransporter } from 'store/TerminalTransporter';
import { PurchaseInvoiceTerminalTransporterBookingLineStore as PurchaseInvoiceTerminalTransporterBookingLineStorex } from 'store/PurchaseInvoiceTerminalTransporterBookingLine';
import { PurchaseInvoice } from './PurchaseInvoice';

export class PurchaseInvoiceTerminalTransporter extends Model {
    static backendResourceName = 'purchase_invoice_terminal_transporter';

    @observable id = null;

    @computed
    get invoiceAmountComputed() {
        if (this.bookingLines == null) {
            return 0;
        }
        return this.bookingLines?.map(line => line.amount ? line.amount : 0).reduce((a, b) => a + b, 0);
    }

    relations() {
        return {
            purchaseInvoice: PurchaseInvoice,
            terminalTransporter: TerminalTransporter,
            bookingLines: PurchaseInvoiceTerminalTransporterBookingLineStorex,
        };
    }
}

export class PurchaseInvoiceStore extends Store {
    Model = PurchaseInvoiceTerminalTransporter;
    static backendResourceName = 'purchase_invoice_terminal_transporter';
}
