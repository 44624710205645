import { Model, Store, Casts } from 'store/Base';
import { observable, computed } from 'mobx';
import { PurchaseInvoiceDocumentStore } from 'store/PurchaseInvoiceDocument';
import { PurchaseInvoiceSubcontract as PurchaseInvoiceSubcontractx } from 'store/PurchaseInvoiceSubcontract';
import { PurchaseInvoiceTerminalTransporter as PurchaseInvoiceTerminalTransporterx } from 'store/PurchaseInvoiceTerminalTransporter';
import { PurchaseInvoiceSelfBill as PurchaseInvoiceSelfBillx } from 'store/PurchaseInvoiceSelfBill';
import { formatMoneyEuro, formatMoneyPound } from 'helpers';
import { Email } from './Email';
import { DateTime } from 'luxon';
import { ContactStore, TYPE_INVOICE } from './Contact';
import { uniq, uniqBy, sortBy } from 'lodash';
import { t } from 'i18n';
import { trimEnd } from 'lodash';

export const TYPE_OTHER = 'other';
export const TYPE_SELF_BILL = 'self_bill';
export const TYPE_TERMINAL_TRANSPORTER = 'terminal_transporter';
export const TYPE_SUBCONTRACT = 'subcontract';
export const TYPES = [TYPE_SELF_BILL, TYPE_TERMINAL_TRANSPORTER];

export const STATUS_DRAFT = 'draft';
export const STATUS_PENDING = 'pending';
export const STATUS_ACCEPTED = 'accepted';
export const STATUS_REJECTED = 'rejected';
export const STATUS_ARCHIVED = 'archived';
export const STATUSES = [STATUS_DRAFT, STATUS_PENDING, STATUS_ACCEPTED, STATUS_REJECTED, STATUS_ARCHIVED];

export const CURRENCY_EURO = 'euro';
export const CURRENCY_POUND = 'pound';
export const CURRENCIES = [CURRENCY_EURO, CURRENCY_POUND];

export const NONE = 0;
export const REWORKED = 1;
export const PRICE_DIFFERENCE = 2;
export const CORRECTION = 4;
export const PURCHASE_DIFFERENCE = 8;
export const FLAGS = [NONE, PRICE_DIFFERENCE, CORRECTION, PURCHASE_DIFFERENCE, REWORKED];

export class PurchaseInvoice extends Model {
    static backendResourceName = 'purchase_invoice';

    @observable id = null;
    @observable type = TYPE_OTHER;
    @observable status = STATUS_DRAFT;
    @observable currency = CURRENCY_EURO;
    @observable invoiceDate = null;
    @observable createdAt = null;
    @observable internalRemarks = null;
    @observable invoiceNumber = null;
    @observable purchasePrice = 0;
    @observable invoiceAmount = 0;
    @observable correction = 0;
    @observable vat = 0;
    @observable sentAt = null;
    @observable draftSentAt = null;
    @observable reasonOfRejection = null;
    @observable flags = NONE;
    @observable draftAcceptanceUuid = null;
    @observable sentPdf = null;
    // @observable anomalyFlags = null;

    _contactStore = new ContactStore();

    relations() {
        return {
            documents: PurchaseInvoiceDocumentStore,
            purchaseInvoiceSubcontract: PurchaseInvoiceSubcontractx,
            purchaseInvoiceTerminalTransporter: PurchaseInvoiceTerminalTransporterx,
            purchaseInvoiceSelfBill: PurchaseInvoiceSelfBillx,
        };
    }

    casts() {
        return {
            createdAt: Casts.datetime,
            updatedAt: Casts.datetime,
            invoiceDate: Casts.date,
            draftSentAt: Casts.date,
            sentAt: Casts.date,
        };
    }

    accept(options = {}) {
        return this.save({
            ...options,
            url: this.url + 'accept/'
        });
    }

    reject(options = {}) {
        return this.save({
            ...options,
            url: this.url + 'reject/'
        });
    }

    calculate(options = {}) {
        const temp = new PurchaseInvoice(this.toJS(), { relations: this.__activeRelations });

        temp.id = null;
        temp.purchaseInvoiceSubcontract.id = null;
        temp.purchaseInvoiceTerminalTransporter.id = null;
        temp.purchaseInvoiceSelfBill.id = null;

        return this.wrapPendingRequestCount(
            temp.save({
                ...options,
                url: this.urlRoot() + 'calculate/'
            }).then(res => {
                this.purchasePrice = res.purchase_price;
                this.invoiceAmount = res.invoice_amount;

                this.purchaseInvoiceSelfBill.tripActivityLines.forEach((tripActivityLine, i) => {
                    const calculatedResponse = res['trip_activity_lines'].find((obj) => obj.id === tripActivityLine.tripActivity.id)

                    if (calculatedResponse != null) {
                        tripActivityLine.amount = calculatedResponse.amount;
                        tripActivityLine.tripActivity.purchasePrice = calculatedResponse.purchase_price;
                    }
                })
            })
        );
    }

    formatWithCurrency(amount) {
        if (this.currency === CURRENCY_EURO) {
            return formatMoneyEuro(amount);
        } else if (this.currency === CURRENCY_POUND) {
            return formatMoneyPound(amount);
        }

        throw new Error(`Unknown currency: ${this.currency}`);
    }

    @computed get downloadUrl() {
        return `/api${this.url}pdf/?download=true`
    }

    @computed
    get draftPdfUrl() {
        return `${trimEnd(this.api.baseUrl, '/')}${this.url}draft_pdf/${this.draftAcceptanceUuid}/`;
    }

    @computed
    get draftPdfDownloadUrl() {
        return `${this.draftPdfUrl}?download=true`;
    }

    @computed get tagLabel() {
        return `PI${this.id}`;
    }

    @computed get editUrl() {
        return `/admin/purchase-invoice/${this.id}/edit`;
    }

    @computed
    get grossAmount() {
        return this.totalNetAmount + (this.totalNetAmount * (this.vat / 1000 ?? 0));
    }

    @computed
    get invoiceAmountComputed() {
        switch (this.type) {
            case TYPE_SELF_BILL:
                return this.purchaseInvoiceSelfBill.invoiceAmountComputed;
            case TYPE_TERMINAL_TRANSPORTER:
                return this.purchaseInvoiceTerminalTransporter.invoiceAmountComputed;
            case TYPE_SUBCONTRACT:
                return this.purchaseInvoiceSubcontract.invoiceAmountComputed;
            default:
                return this.invoiceAmount;

        }
    }

    @computed
    get priceDifference() {
        return this.purchasePrice - this.invoiceAmountComputed;
    }

    @computed
    get totalNetAmount() {
        return this.invoiceAmountComputed + this.correctionTotal;
    }

    @computed get formattedPurchasePrice() {
        return this.formatWithCurrency(this.purchasePrice);
    }

    @computed get formattedPriceDifference() {
        return this.formatWithCurrency(this.priceDifference);
    }

    @computed get formattedNetAmount() {
        return this.formatWithCurrency(this.totalNetAmount);
    }

    @computed get formattedInvoiceAmount() {
        return this.formatWithCurrency(this.invoiceAmountComputed);
    }

    @computed get formattedGrossAmount() {
        return this.formatWithCurrency(this.grossAmount);
    }

    @computed get formattedCorrection() {
        return this.formatWithCurrency(this.correction);
    }

    @computed get correctionTotal() {
        switch (this.type) {
            case TYPE_SELF_BILL:
                return this.correction + this.purchaseInvoiceSelfBill.correctionTotal;
          
            default:
                return this.correction;

        }
    }

    @computed get correctionTotalDailyRate() {
        switch (this.type) {
            case TYPE_SELF_BILL:
                return this.purchaseInvoiceSelfBill.correctionTotalDailyRate;

            default:
                return null;

        }
    }

    @computed get correctionTotalOther() {
        switch (this.type) {
            case TYPE_SELF_BILL:
                return this.purchaseInvoiceSelfBill.correctionTotalOther;

            default:
                return null;
        }
    }

    @computed get formattedCorrectionTotal() {
        return this.formatWithCurrency(this.correctionTotal);
    }

    @computed get formattedDailyRateCorrection() {
        return this.formatWithCurrency(this.correctionTotalDailyRate);
    }

    @computed get formattedOtherCorrection() {
        return this.formatWithCurrency(this.correctionTotalOther);
    }

    @computed
    get isRework() {
        return this.flags & REWORKED
    }

    @computed
    get isFlagged() {
        return this.flags & PRICE_DIFFERENCE || this.flags & CORRECTION || this.flags & PURCHASE_DIFFERENCE
    }

    @computed
    get isFlaggedReason() {
        if (this.flags & PRICE_DIFFERENCE) {
            return t('purchaseInvoice.overview.popups.priceDifference');
        }
        if (this.flags & CORRECTION) {
            return t('purchaseInvoice.overview.popups.correction');
        }
        if (this.flags & PURCHASE_DIFFERENCE) {
            return t('purchaseInvoice.overview.popups.purchasePriceAndAmountDiff');
        }

        return null
    }

    getTrucksList() {
        try {
            let arrTruckLicensePlate = []
            uniqBy(this.purchaseInvoiceSelfBill.tripActivityLines.models, tripActivity => tripActivity.tripActivity.trip.truck.licensePlate).map(tripActivity => (
                arrTruckLicensePlate.push(tripActivity.tripActivity.trip.truck.licensePlate)
            ))
            return arrTruckLicensePlate;
        } catch (error) {
            return ''
        }
    }
    getTrucksFleetNumberList() {
        try {
            let arrTruckLicensePlate = []
            uniqBy(this.purchaseInvoiceSelfBill.tripActivityLines.models, tripActivity => tripActivity.tripActivity.trip.truck.fleetNumber).map(tripActivity => (
                arrTruckLicensePlate.push(tripActivity.tripActivity.trip.truck.fleetNumber)
            ))
            return arrTruckLicensePlate;
        } catch (error) {
            return ''
        }
    }

    getTruckFleetNumber() {
        try {
            let arrTruckFleetNumber = []
            uniqBy(this.purchaseInvoiceSelfBill.tripActivityLines.models, tripActivity => tripActivity.tripActivity.trip.truck.fleetNumber).map(tripActivity => (
                arrTruckFleetNumber.push(tripActivity.tripActivity.trip.truck.fleetNumber)
            ))
            return arrTruckFleetNumber.length > 1 ? '' : arrTruckFleetNumber
        } catch (error) {
            return '';
        }
    }

    getWeekNumber() {
        try {
            let dt = this.purchaseInvoiceSelfBill.tripActivityLines.at(0).tripActivity.activity.orderedArrivalDatetimeFrom;
            return DateTime.fromISO(dt).toFormat('W');
        } catch (error) {
            return '';
        }
    }
    getWeekNumbers() {
        let arrActivityDate = [];
        try {
            this.purchaseInvoiceSelfBill.tripActivityLines.map(item => {
                arrActivityDate.push(item.tripActivity.activity.weekNo);
                return arrActivityDate;
            });
            return sortBy(uniq(arrActivityDate));
        } catch (error) {
            return '';
        }
    }

    getContactStore() {
        this._contactStore.params = {
            '.type': TYPE_INVOICE,
            '.email_address:not': '',
            '.trucking_company.id': this.purchaseInvoiceSelfBill.truckingCompany.id,
        }
        return this._contactStore
    }

    getEmail() {
        let truckFleetNumber = this.getTruckFleetNumber().length > 0 ? 'Truck ' + this.getTruckFleetNumber() + '\n' : '';
        return new Email().generate(
            `Self-billing Invoice ${this.id} regarding truck ${this.getTruckFleetNumber()} in week ${this.getWeekNumbers()}`,
            `Dear ${this.purchaseInvoiceSelfBill.truckingCompany.name} team, \n` +
            `Please find in the attachment your self-billing invoice for the following service;\n` +
            `${truckFleetNumber}` +
            `Week: ${this.getWeekNumbers()} \n` +
            `For your service, we do not need to receive an invoice. You can expect payment upon stated payment terms in the self-billing Invoice. \n` +
            `If you have any questions, do not hesitate to contact us. \n\n` +
            `Kind Regards, \n` +
            `The Dalessi Team \n` +
            `administratie@dalessi.nl`,
            []
        )
    }

    @computed get pdfPreviewUrl() {
        return `/api${this.url}pdf/`;
    }

    @computed get pdfDownloadUrl() {
        return `${this.pdfPreviewUrl}?download=true`;
    }

    @computed get attachmentName() {
        return `Purchase Invoice - ${this.id}`;
    }

    getAttachmentInfo() {
        return [{
            id: `E${this.id}`,
            url: this.pdfDownloadUrl,
            previewUrl: this.pdfPreviewUrl,
            name: this.attachmentName,
            optional: false,
        }]
    }
}

export class PurchaseInvoiceStore extends Store {
    Model = PurchaseInvoice;
    static backendResourceName = 'purchase_invoice';
}
