import React from 'react';
import { action, observable, computed, toJS } from 'mobx';
import { Model as BModel, Store as BStore, BinderApi, Casts as BCasts } from 'mobx-spine';
import { uniq, mapKeys, mapValues, get, isArray, omit, result } from 'lodash';
import moment from 'moment';
import { snakeToCamel, FRONTEND_API_BASE_URL, relationsToNestedKeys, forNestedRelations } from 'helpers';
import concatWithoutOverlap from 'helpers/concatWithoutOverlap';
import { Popup, Label } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { LocalTime } from 'js-joda';

class MyApi extends BinderApi {
    baseUrl = FRONTEND_API_BASE_URL;

    fetchStore({ url, data, requestOptions }) {
        return this.get(url, data, requestOptions).then(res => {
            return {
                response: res,
                data: res.data,
                repos: res.with,
                relMapping: res.with_mapping,
                reverseRelMapping: res.with_related_name_mapping,
                totalRecords: res.meta.total_records,
                meta: res.meta,
            };
        });
    }
}

/**
 * Call options.registerCancelToken and return options extended with the cancelToken.
 *
 * Works together with component/Component.
 */
export function parseRegisterCancelToken(options) {
    const extraOptions = {};

    if (options.registerCancelToken) {
        extraOptions['cancelToken'] = options.registerCancelToken();
    }

    return {
        ...options,
        ...extraOptions,
    };
}

export const myApi = new MyApi();

// Stolen from mobx spine, since it's not exported.
export function parseBackendValidationErrors(response) {
    const valErrors = get(response, 'data.errors');
    if (response.status === 400 && valErrors) {
        return valErrors;
    }
    return null;
}

export class Model extends BModel {
    api = myApi;
    static idPrefix = '';
    static idColor = '';

    getUrl() {
        return '';
    }

    @observable __actuallyUsefulErrors = {}

    @computed get _id() {
        return this.constructor.idPrefix + (this.id ? this.id : ' New');
    }

    getLabel({ hover, ...props } = {}) {
        let label = (
            <Label color={this.constructor.idColor} {...props}>
                {this._id}
            </Label>
        );
        if (hover !== undefined) {
            label = (
                <Popup trigger={label} content={hover} />
            );
        }
        return label;
    }

    getLink(props = {}) {
        return this.getLabel({
            as: Link,
            to: this.getUrl(),
            style: { textDecoration: 'none' },
            ...props,
        });
    }

    @computed get actuallyUsefulErrors() {
        return this.__actuallyUsefulErrors;
    }

    markChanged(field) {
        if (!this.__changes.includes(field)) {
            this.__changes.push(field);
        }
    }

    getAllFlattenedWildcardErrors() {
        let errors = this.getWildcardErrors();

        this.__activeCurrentRelations.forEach(attr => {
            errors = errors.concat(this[attr].getWildcardErrors());
        });
        return uniq(errors);
    }

    getWildcardErrors() {
        return toJS(this.backendValidationErrors['*']) || [];
    }

    // Overwrite to for better backend validation.
    @action
    parseValidationErrors(valErrors) {
        const bname = this.constructor.backendResourceName;

        if (valErrors[bname]) {
            const id = this.getInternalId();
            // When there is no id or negative id, the backend may use the string 'null'. Bit weird, but eh.
            const errorsForModel =
                valErrors[bname][id] || valErrors[bname]['null'];
            if (errorsForModel) {
                const camelCasedErrors = mapKeys(errorsForModel, (value, key) =>
                    snakeToCamel(key)
                );
                const formattedErrors = mapValues(
                    camelCasedErrors,
                    valError => {
                        return valError.map(obj => obj.message || obj.code); // Show backend message if available. T16564
                    }
                );
                this.__backendValidationErrors = formattedErrors;
                this.__actuallyUsefulErrors = camelCasedErrors;
            }
        }

        this.__activeCurrentRelations.forEach(currentRel => {
            this[currentRel].parseValidationErrors(valErrors);
        });
    }

    _subscriptions = [];

    _subscribeField(fieldName) {
        const modelName = this.constructor.backendResourceName.replace('_', '');
        const subscription = subscribe({
            target: `${modelName}-update-field`,
            id: this.id,
            field: fieldName,
        }, (payload) => {
            const field = snakeToCamel(fieldName);
            this._parsePayload(field, payload);
        })
        this._subscriptions.push(subscription);
        return subscription;
    }

    _parsePayload(field, payload) {
        if (typeof this[field]?.parse === 'function') {
            if (payload.data != null) {
                this[field].parse(payload.data);
            } else {
                this[field] = null;
            }
        } else {
            if (typeof this.casts === 'function' && typeof this.casts()[field]?.parse === 'function') {
                this[field] = this.casts()[field].parse(field, payload.data);
            } else {
                this[field] = payload.data;
            }
        }

    }

    subscribe(fields) {
        if (!isArray(fields)) {
            fields = [fields];
        }

        const subscriptions = [];
        fields.forEach(field => {
            subscriptions.push(this._subscribeField(field));
        });
        return subscriptions;
    }

    unsubscribe(subscriptions) {
        subscriptions.forEach(subscription => {
            subscription.unsubscribe();
        })
    }

    unsubscribeAll() {
        this._subscriptions.forEach(subscription => {
            subscription.unsubscribe();
        })
    }

    restore() {
        return this.api.post(this.url);
    }

    @computed
    get isNew() {
        return !this[this.constructor.primaryKey] || this[this.constructor.primaryKey] < 0;
    }

    validate(options = {}) {
        if (options.relations && options.relations.length > 0) {
            return this._validateAll(options);
        } else {
            return this._validate(options);
        }
    }

    @action
    _validate(options = {}) {
        this.clearValidationErrors();
        return this.wrapPendingRequestCount(
            this.__getApi()
            .saveModel({
                url: options.url || `${result(this, 'urlRoot')}validate/`,
                data: this.toBackend({
                        data: options.data,
                        mapData: options.mapData,
                        fields: options.fields,
                        onlyChanges: options.onlyChanges,
                    }),
                isNew: this.isNew,
                requestOptions: omit(options, 'url', 'data', 'mapData')
            })
            .then(action(res => {
                this.clearUserFieldChanges();
                // TODO: add file validations
            }))
            .catch(
                action(err => {
                    if (err.valErrors) {
                        this.parseValidationErrors(err.valErrors);
                    }
                    throw err;
                })
            )
        );
    }

    @action
    _validateAll(options = {}) {
        this.clearValidationErrors();
        return this.wrapPendingRequestCount(
            this.__getApi()
            .saveAllModels({
                url: `${result(this, 'urlRoot')}validate/`,
                model: this,
                data: this.toBackendAll({
                    data: options.data,
                    mapData: options.mapData,
                    nestedRelations: relationsToNestedKeys(options.relations || []),
                    onlyChanges: options.onlyChanges,
                }),
                requestOptions: omit(options, 'relations', 'data', 'mapData'),
            })
            .then(action(res => {
                this.saveFromBackend(res);
                this.clearUserFieldChanges();

                forNestedRelations(this, relationsToNestedKeys(options.relations || []), relation => {
                    if (relation instanceof Model) {
                        relation.clearUserFieldChanges();
                    } else {
                        relation.clearSetChanges();
                    }
                });

                // TODO: add file validations
            }))
            .catch(
                action(err => {
                    if (err.valErrors) {
                        this.parseValidationErrors(err.valErrors);
                    }
                    throw err;
                })
            )
        );
    }
}

export class Store extends BStore {
    api = myApi;

    @observable meta = {};

    markChanged() {
        this.__setChanged = true;
    }

    getByCid(cid) {
        return this.models.find(model => model.cid === cid);
    }

    getWildcardErrors() {
        let errors = [];
        this.models.forEach(model => {
            errors = errors.concat(model.getWildcardErrors());
        });
        return errors;
    }

    ignoreSetChanges(func) {
        const hasSetChanges = this.hasSetChanges;
        const res = func();

        if (res instanceof Promise) {
            res.catch(() => { }).then(() => {
                if (!hasSetChanges) {
                    this.clearSetChanges();
                }
            });

            return res;
        } else if (!hasSetChanges) {
            this.clearSetChanges();
        }

        return res;
    }

    fromBackend({ meta = {}, ...rest }) {
        this.meta = meta;
        return super.fromBackend(rest);
    }

    toBackend(options = {}) {
        return this.map(o => o.id)
    }
}

export const api = myApi;

export const Casts = {
    ...BCasts,
    decimal: {
        parse(attr, value) {
            if (value === null) {
                return null;
            }
            return value.replace(/,/g, '').replace('.', ',');
        },
        toJS(attr, value) {
            if (value === null || value === '') {
                return null;
            }
            return value.replace(/\./g, '').replace(',', '.');
        },
    },
    durationMinutes: {
        parse(attr, value) {
            if (value === null) {
                return null;
            }
            return moment.duration(value, 'minutes');
        },
        toJS(attr, value) {
            if (value === null) {
                return null;
            }
            // https://github.com/CodeYellowBV/mobx-spine/issues/57
            if (value === 0) {
                return 0;
            }
            return value.asMinutes();
        },
    },
    duration: {
        parse(attr, value) {
            if (value === null) {
                return null;
            }

            return moment.duration(value);
        },
        toJS(attr, value) {
            if (value === null) {
                return null;
            }

            return value.toISOString();
        },
    },
    nullableDatetime: {
        parse(attr, value) {
            return Casts.datetime.parse(attr, value);
        },
        toJS(attr, value) {
            if (value === null) {
                return null;
            }

            return Casts.datetime.toJS(attr, value);
        },
    },
    datetimeNaive: {
        parse(attr, value) {
            return Casts.datetime.parse(attr, value?.substring(0, 26));
        },
        toJS(attr, value) {
            return Casts.datetime.toJS(attr, value) ? Casts.datetime.toJS(attr, value).substring(0, 19) + '+0000': null
        },
    },
    datetimeUTC: {
        parse(attr, value) {
            return Casts.datetime.parse(attr, value).toUTC();
        },
        toJS(attr, value) {
            return Casts.datetime.toJS(attr, value);
        },
    },
    file: {
        parse(attr, value) {
            if (value) {
                return concatWithoutOverlap(myApi.baseUrl, value);
            }

            return null;
        },
        toJS(attr, value) {
            return value;
        },
    },
    time: {
        parse(attr, value) {
            if (value) {
                return LocalTime.parse(value);
            }

            return null;
        },
        toJS(attr, value) {
            if (value) {
                return value.toString();
            }

            return value;
        },
    }
};


export function OrderedStore(UnorderedStore, orderingField) {
    return class extends UnorderedStore {
        comparator = orderingField;

        addPos(pos, ...args) {
            this.forEach((obj, i) => {
                if (i >= pos) {
                    obj.setInput(orderingField, i + 1);
                }
            });

            const model = this.add(...args);
            model[orderingField] = pos;
            this.sort();
            return model;
        }

        addFirst(...args) {
            return this.addPos(0, ...args);
        }

        _newModel(model = null, i) {
            const m = super._newModel(model);

            // if (!(orderingField in model)) {
            if (this.length > 0) {
                const nextOrdering = Math.max(...this.map(aModel => aModel[orderingField])) + 1;
                m[orderingField] = isFinite(nextOrdering) ? nextOrdering : this.length + 1;
            } else {
                m[orderingField] = i;
            }
            // }

            return m;
        }
    }
}


export function subscribe(room, callback) {
    const result = api.socket.subscribe({
        onPublish: callback,
        room,
    });

    result.unsubscribe = function () {
        // Fix a rare bug where cypress switches to another view before we are
        // subscribed to this view which sometimes causes cypress to crash
        if (result !== undefined && api.socket != null) {
            api.socket.unsubscribe(result);
        }
    }

    return result;
}
