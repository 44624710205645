import { Model } from 'store/Base';
import { action, observable } from 'mobx';


export class Email extends Model {
    @observable id = null;
    @observable recipients = [];
    @observable subject = '';
    @observable content = '';
    @observable documents;

    @action
    generate(subject, content, recipients, documents) {
        this.subject = subject;
        this.content = content
        this.recipients = recipients;
        this.documents = documents;

        return this;
    }
}