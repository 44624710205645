import { Model, Store } from 'store/Base';
import { observable } from 'mobx';
import { TruckingCompanyStore } from './TruckingCompany';
import { ServiceType } from './ServiceType';
import { ServiceSubtypeLocationStore } from './ServiceSubtypeLocation';
import OWNERSHIP from './enums/ServiceSubtypeOwnership';

export class ServiceSubtype extends Model {
    static backendResourceName = 'service_subtype';

    @observable id = null;
    @observable name = null;
    @observable asset = null;
    @observable ownership = OWNERSHIP.ALL;

    @observable recurrent = false;
    @observable expirationBasedOn = null;
    @observable expirationAmount = null;
    @observable expirationUnit = null;
    @observable newBeforeExpirationAmount = null;
    @observable newBeforeExpirationUnit = null;
    @observable raisePriorityBeforeExpirationAmount = null;
    @observable raisePriorityBeforeExpirationUnit = null;

    @observable hasLocations = false;

    @observable allowDocuments = false;
    @observable allowInvoices = false;

    relations() {
        return {
            serviceType: ServiceType,
            owners: TruckingCompanyStore,
            locations: ServiceSubtypeLocationStore,
        }
    }
}

export class ServiceSubtypeStore extends Store {
    Model = ServiceSubtype;
    static backendResourceName = 'service_subtype';
}
