import { DATETIME_FORMAT_SHORT, formatDatetime } from 'helpers';
import { observable, action, computed } from 'mobx';
import { Casts, Model } from 'store/Base';
import CustomsStatus from 'store/enums/CustomsStatus';
import ExportNotificationStatus from 'store/enums/ExportNotificationStatus';
import { Activity } from './Activity';

export class Customs extends Model {
    static backendResourceName = 'customs';

    @observable id = null;
    @observable activity = null;

    @observable arrived = false;
    @observable cleared = false;
    @observable hasDetails = false;
    @observable advised = false;
    @observable discharged = false;

    @observable manualStatus = null;
    @observable currentStatus = null;

    @observable ataPort = null;
    @observable atdPort = null;
    @observable ataBerth = null;
    @observable atdBerth = null;

    @observable exportNotificationStatus = ExportNotificationStatus.NONE;
    @observable exportNotificationStatusDescription = null;

    get formattedAtaBerth() {
        return formatDatetime(this.ataBerth, DATETIME_FORMAT_SHORT)
    }

    get formattedAtdBerth() {
        return formatDatetime(this.atdBerth, DATETIME_FORMAT_SHORT)
    }

    relations() {
        return {
            activity: Activity,
        };
    }

    casts() {
        return {
            ataPort: Casts.datetime,
            atdPort: Casts.datetime,
            ataBerth: Casts.datetime,
            atdBerth: Casts.datetime,
        };
    }

    @computed get calculatedStatus() {
        return this.manualStatus ?? this.currentStatus;
    }

    setNextManualStatus = async () => {
        const status = this.manualStatus ?? this.currentStatus;
        const nextStatus = this._getNextStatus(status);
        this.setManualStatus(nextStatus);
    }

    @action
    setManualStatus = async (status) => {
        this.api.post(`${this.url}set_status/`, { new_status: status }).then(res => {
            this.manualStatus = status;
        });
    }

    clearManualStatus = async () => {
        this.setManualStatus(null);
    }

    _getNextStatus(status) {
        switch(status) {
            case CustomsStatus.UNKNOWN:
                return CustomsStatus.NOT_READY;
            case CustomsStatus.NOT_READY:
                return CustomsStatus.READY;
            case CustomsStatus.READY:
                return CustomsStatus.UNKNOWN;
            default:
                return CustomsStatus.UNKNOWN;
        }
    }
}
