import { Model, Store, OrderedStore } from 'store/Base';
import { computed, observable } from 'mobx';
import { Activity } from './Activity';
import { Trip } from './Trip';
import { PurchaseInvoiceSelfBillTripActivityLineStore } from './PurchaseInvoiceSelfBillTripActivityLine';
import PurchaseInvoiceStatus, { NOT_INVOICEABLE, NOT_PAID } from './enums/PurchaseInvoiceStatus';

export class TripActivity extends Model {
    static backendResourceName = 'trip_activities';

    @observable id = null;
    @observable ordering = 0;
    @observable plannedKm = 0;
    @observable roadPricing = 0;
    @observable additionalCost;
    @observable purchasePrice = null;
    @observable purchaseInvoiceStatus = NOT_PAID;

    relations() {
        return {
            trip: Trip,
            prevActivity: Activity,
            activity: Activity,
            purchaseInvoiceLines: PurchaseInvoiceSelfBillTripActivityLineStore
        };
    }

    triggerCalculateKm() {
        return this.api.post(`${this.url}trigger_calculate_km/`);
    }

    markAsNotInvoiceable() {
        this.purchaseInvoiceStatus = NOT_INVOICEABLE;
        this.setInput('purchaseInvoiceStatus', NOT_INVOICEABLE);
        return this.save({
            fields: ['purchaseInvoiceStatus']
        });
    }

    @computed get invoiceStatusColorOverview() {
        switch (this.purchaseInvoiceStatus) {
            case PurchaseInvoiceStatus.NOT_PAID:
                return 'var(--red-300)';
            case PurchaseInvoiceStatus.IN_PROGRESS:
                return 'var(--orange-300)'
            case PurchaseInvoiceStatus.TRANSFERRED:
                return 'var(--blue-300)'
            case PurchaseInvoiceStatus.PAID:
                return 'var(--green-300)'

            default:
                return 'var(--gray-300)';
        }
    }
}

export class UnorderedTripActivityStore extends Store {
    Model = TripActivity;
    static backendResourceName = 'trip_activities';

    finalizeLoads() {
        return this.api.post(`/${UnorderedTripActivityStore.backendResourceName}/finalize_loads/`);
    }
}

export const TripActivitiesStore = OrderedStore(UnorderedTripActivityStore, 'ordering');
