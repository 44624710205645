import { Model, Store } from 'store/Base';
import { observable } from 'mobx';

export class District extends Model {
    static backendResourceName = 'district';

    @observable id = null;
    @observable name = '';
    @observable area = {
        cords: null,
        srid: null,
    };
}

export class DistrictStore extends Store {
    Model = District;
    static backendResourceName = 'district';
}
