import { tint } from 'polished';
const primaryColor = '#ebbb12';
const tintedPrimaryColor = tint(0.15, primaryColor);
const buttonColor = '#fadc73';
const dangerColor = '#dc0818';
const warningColor = '#ffc107';

export const theme = {
    primaryColor,
    tintedPrimaryColor,
    buttonColor,
    dangerColor,
    warningColor,
};

// export const primaryColorNav = '#2d303b';
// export const menuButtonColorSelected = '#B0BEC5';
