import { observable } from 'mobx';
import { Casts, Model, Store } from './Base';
import { UserStore } from "./User";
import { omit } from "lodash";
import { GroupStore } from "./User";
import { t } from '../i18n'

export const METABASE_PARAM_OPTIONS = [
    {
        value: 'user',
        text: t('metabase.field.params.value.user')
    },
]

export class Metabase extends Model {
    static backendResourceName = 'metabase';

    @observable id = null;
    @observable createdAt = null;
    @observable updatedAt = null;
    @observable dashboardId = null;
    @observable enabled = false;
    @observable name = '';
    @observable metabaseUrl = null;
    @observable sequenceNumber = null;
    @observable params = []

    relations() {
        return {
            users: UserStore,
            groups: GroupStore,
        };
    }

    casts() {
        return {
            createdAt: Casts.datetime,
            updatedAt: Casts.datetime,
        };
    }


    toBackend(options = {}) {
        // We do not want to send the metabase_url to the backend
        // since this gets calculated there based on the metabase secret key
        return omit(super.toBackend(options), 'metabase_url');
    }

}

export class MetabaseStore extends Store {
    Model = Metabase;
    static backendResourceName = 'metabase';
}
