import { Model, Store } from 'store/Base';
import { observable } from 'mobx';
import { PurchaseInvoiceSelfBillTripActivityLine } from './PurchaseInvoiceSelfBillTripActivityLine';
import CORRECTION_TYPES from './enums/CorrectionType';

export class PurchaseInvoiceSelfBillTripActivityLineCorrection extends Model {
    static backendResourceName = 'purchase_invoice_self_bill_trip_activity_line_correction';

    @observable id = null;
    @observable type = CORRECTION_TYPES.OTHER_COSTS;
    @observable remark = '';
    @observable amount = 0;

    relations() {
        return {
            purchaseInvoiceSelfBillTripActivityLine: PurchaseInvoiceSelfBillTripActivityLine,
        };
    }
}

export class PurchaseInvoiceSelfBillTripActivityLineCorrectionStore extends Store {
    Model = PurchaseInvoiceSelfBillTripActivityLineCorrection;
    static backendResourceName = 'purchase_invoice_self_bill_trip_activity_line_correction';
}
