import Component from 'component/Component';
import PageHeader from 'component/PageHeader';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { Body, Content, ContentContainer, Sidebar, Toolbar } from 're-cy-cle';
import React from 'react';
import { Icon } from 'semantic-ui-react';
import styled from 'styled-components';

const StyledBackIcon = styled(Icon)`
    cursor: pointer;
    margin-right: 1.5rem!important;
`

export const Main = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

export const TopSection = styled.section`
    font-size: 24px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 8px 8px 8px 24px;
    background-color: var(--gray-50);
    width: 100%;
    height: 48px;
    border-bottom: 2px solid var(--gray-300);
`;

export const ContentSection = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
`;
export const ScrollContent = styled(Content)`
    scroll-behavior: smooth;
`;


@observer
export default class ViewEdit extends Component {
    static propTypes = {
        renderTitle: PropTypes.func,
        renderSidebarContent: PropTypes.func,
        renderMainContent: PropTypes.func,
        renderToolbarContent: PropTypes.func,
        isModal: PropTypes.bool,
    };

    _renderMainContent() {
        const { renderMainContent } = this.props;

        if (renderMainContent != null) {
            return (
                <ScrollContent>
                    {renderMainContent()}
                </ScrollContent>
            )
        }
        return '[NOT IMPLEMENTED] No Main Content defined'
    }

    _renderSidebarContent() {
        const { renderSidebarContent } = this.props;

        if (renderSidebarContent != null) {
            return (
                <Sidebar medium>
                    {renderSidebarContent()}
                </Sidebar>
            )
        }
        return null;
    }

    _renderToolbarContent() {
        const { renderToolbarContent } = this.props;

        if (renderToolbarContent != null) {
            return renderToolbarContent();
        }

        return '[NOT IMPLEMENTED] No Toolber defined'
    }

    _renderTitle() {
        const { renderTitle } = this.props;

        if (renderTitle != null) {
            return renderTitle();
        }

        return '[NOT IMPLEMENTED] No Title Defined'
    }

    render() {
        const { isModal } = this.props;
        return (
            <Body>
                <ContentContainer>
                    <Main>
                        <TopSection>
                            {!isModal && <StyledBackIcon onClick={() => window.history.back()} name='arrow left' size='small'/>}
                            <PageHeader>
                                {this._renderTitle()}
                            </PageHeader>
                        </TopSection>
                        <ContentSection>
                            {this._renderSidebarContent()}
                            {this._renderMainContent()}
                        </ContentSection>
                    </Main>
                </ContentContainer>
                <Toolbar>
                    {this._renderToolbarContent()}
                </Toolbar>
            </Body>
        );

    }

}
