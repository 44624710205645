import { observer } from 'mobx-react';
import React, { Component } from 'react';
import styled from 'styled-components';

const Container = styled.a`
    width: 50px;
    height: 50px;
    position: absolute;
    left: 0;
    bottom: 4px;
    #cy-logo-c {
        fill: rgba(0, 0, 0, .16);
        transition: fill 600ms ease;
    }
    #cy-logo-y {
        fill: rgba(0, 0, 0, 0.16);
        transition: fill 600ms ease;
    }
    &:hover {
        #cy-logo-c {
            fill: #ebbb12;
        }
        #cy-logo-y {
            fill: #000;
        }
    }
`;

@observer
export default class CyLink extends Component {

    render() {

        return (
            <Container
                href="https://www.codeyellow.nl/"
                target="_blank"
                onClick={(e) => e.stopPropagation()}
            >
                <svg width="50" height="50" viewBox="0 0 1 1">
                    <g transform="scale(0.7) translate(0.1, 0.1)">
                        <path id="cy-logo-c" d="
                                M 0.25,0.25 l -0.21875,0.21875 a 0.04419,0.04419 1 0 0 0,0.0625
                                l 0.28125,0.28125
                                l 0.125,-0.125
                                l -0.15625,-0.15625 a 0.04419,0.04419 1 0 1 0,-0.0625
                                l 0.1875,-0.1875 a 0.04419,0.04419 1 0 1 0.0625,0
                                l 0.15625,0.15625
                                l 0.125,-0.125
                                l -0.28125,-0.28125 a 0.04419,0.04419 1 0 0 -0.0625,0 Z
                            "/>
                        <path id="cy-logo-y" d="
                                M 0.46875,0.96875 a 0.04419,0.04419 1 0 0 0.0625,0
                                l 0.4375,-0.4375 a 0.04419,0.04419 1 0 0 0,-0.0625
                                l -0.09375,-0.09375
                                l -0.15625,0.15625 a 0.04419,0.04419 1 0 1 -0.0625,0
                                l -0.15625,-0.1562
                                l -0.125,0.125
                                l 0.15625,0.15625 a 0.04419,0.04419 1 0 1 0,0.0625
                                l -0.15625,0.15625
                            "/>
                    </g>
                </svg>
            </Container>
        );
    }
}
