import React from 'react';
import { camelToSnake } from 'helpers';
import PropTypes from 'prop-types';

export default function liveTarget(TargetComponent) {
    return class extends React.Component {
        static propTypes = {
            target: PropTypes.object.isRequired,
            name: PropTypes.string.isRequired,
            liveName: PropTypes.string
        };
        
        componentDidMount() {
            const { target } = this.props;
            this.subscribe(target);
        }

        componentDidUpdate(prevProps) {
            if (this.props.target !== prevProps.target) {
                this.unsubscribe(prevProps.target);
                this.subscribe(this.props.target);
            }
        }

        componentWillUnmount() {
            const { target } = this.props;
            this.unsubscribe(target);
        }

        subscribe(target) {
            const { name, liveName } = this.props;
            let propName = liveName;
            if (liveName == null || liveName === '') {
                propName = name;
            }
            const snakeCase = camelToSnake(propName);
            if (target != null) {
                target.subscribe(snakeCase);
            }
        }

        unsubscribe(target) {
            if (target != null) {
                target.unsubscribeAll();
            }
        }

        render() {
            return (
                <TargetComponent {...this.props} />
            );
        }
    }
}
