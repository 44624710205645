import { Model, Store } from 'store/Base';
import { observable } from 'mobx';
import { formatMoneyEuro, formatPercentage } from "../helpers";

export class TruckingCompanyTariffKmRate extends Model {
    static backendResourceName = 'trucking_company_tariff_km_rate';

    @observable id = null;
    @observable price = null;
    @observable minKm = null;
    @observable maxKm = null;
    @observable fuelSurchargeFactor = null;

    /**
     * Converts the price to a sting with "€"
     * @returns {*}
     */
    get priceString() {
        return formatMoneyEuro(this.price/10)
    }

    /**
     * Converts the fuelSurchargeFactor to a string with "%"
     * @returns {string}
     */
    get fuelSurchargeFactorString() {
        return formatPercentage(this.fuelSurchargeFactor / 1000)
    }
}

export class TruckingCompanyTariffKmRateStore extends Store {
    Model = TruckingCompanyTariffKmRate;
    static backendResourceName = 'trucking_company_tariff_km_rate';
}
