import React from 'react';
import PropTypes from 'prop-types';

export default function inlineTarget(TargetComponent) {
    return class extends React.Component {
        static propTypes = {
            target: PropTypes.object.isRequired,
            name: PropTypes.string.isRequired,
        };

        render() {
            const { target, name } = this.props;

            return (
                <TargetComponent {...this.props}
                    onChange={(newValue) => {
                        target[name] = newValue;
                        target.save({
                            fields: [name]
                        });
                    }} />
            );
        }
    }
}
