export const ONTARGET = 'ONTARGET';
export const ARRIVED = 'ARRIVED';
export const ABORTED = 'ABORTED';
export const CHANGED_TO_EARLY = 'CHANGED_TO_EARLY';
export const CHANGED_TO_LATER = 'CHANGED_TO_LATER';

export const ACTIVITY_STATUSES = [ONTARGET, ARRIVED, ABORTED, CHANGED_TO_EARLY, CHANGED_TO_LATER];

export const getTransicsETAStatusColor = (status) => {
    switch (status) {
        case CHANGED_TO_EARLY:
        case CHANGED_TO_LATER:
        case ONTARGET:
            return 'var(--blue-100)'
        case ARRIVED:
            return 'var(--green-100)'
        case ABORTED:
            return 'var(--red-100)'
        default:
            return 'var(--gray-100)';
    }
}

export default { ONTARGET, ARRIVED, ABORTED, CHANGED_TO_EARLY, CHANGED_TO_LATER}
