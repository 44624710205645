import { BlueLink } from 'component/Compact/BlueLink';
import { TargetSelect } from 'component/Target';
import ModalEditWrapper from 'container/Modals/ModalEditWrapper';
import TaskEdit from 'container/Task/Edit';
import { DATETIME_FORMAT_PRETTY } from 'helpers';
import { getCurrentUser } from 'helpers/currentUser';
import showModal, { hideModal } from 'helpers/modal';
import { t } from 'i18n';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Button, Icon } from 'semantic-ui-react';
import { afterSave } from '@code-yellow/spider';
import { getStatusColor, TASK_STATUSES } from 'store/enums/TaskStatus';
import { Task, TaskStore } from 'store/Task';
import styled from 'styled-components';

const CustomToolbar = styled.div`
    background-color: var(--gray-50);
    height: 46px;
    padding: 8px 16px 8px 8px;
    display: flex;
    flex-direction: row-reverse;
    justify-content: right;
    align-items: center;
`

const FormContainer = styled.div`
    padding: 16px 24px;
    .dropdown {
        width: 100%;
    }
`

const TopSection = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 16px 24px 0px 24px;
`

const StyledTask = styled.div`
    padding: 12px 24px;
    background-color: ${({ color }) => color};

    :hover {
        background-color: var(--blue-50);
    }
`

const StyledTaskButtons = styled.div`
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    font-weight: bold;
`

const StyledTaskTitle = styled.div`
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    margin-bottom: 4px;
`

const StyledTaskDescription = styled.div`
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    margin-bottom: 4px;


    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
`

const StyledTaskCreated = styled.div`
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: var(--gray-500);
    margin-bottom: 8px;
`

const ContentTitle = styled.div`
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
`

const TasksContainer = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
`

const TasksList = styled.div`
    height: 100%;
    overflow-y: auto;
`

@observer
export default class SidebarContentTasks extends Component {
    static propTypes = {
        hideSidebarContent: PropTypes.func.isRequired,
    };

    @observable taskStore = new TaskStore({ relations: ['createdBy'] });

    async componentDidMount() {
        const currentUser = getCurrentUser();

        // Only load your tasks
        if (currentUser?.id != null) {
            this.taskStore.params['.assigned.id'] = getCurrentUser()?.id;
            this.taskStore.fetch();
        }
    }

    setStatusFilter(values) {
        this.taskStore.params['.status:in'] = values.toString();
        this.taskStore.fetch();
    }


    onAddTask() {
        this.hide()

        const task = new Task({ id: null }, {
            relations: ['assigned', 'createdBy', 'subscribers'],
        });

        showModal(ModalEditWrapper, {
            content: (
                <TaskEdit task={task} afterSave={() => {
                    hideModal();
                    afterSave();
                }}></TaskEdit>
            ),
        })
    }

    openTaskModal(taskId) {
        this.hide()

        const task = new Task({ id: taskId }, {
            relations: ['assigned', 'createdBy', 'subscribers',
                'tagTrucks', 'tagTrailers', 'tagTasks', 'tagOrders', 'tagInvoices',
                'tagBookings', 'tagActivities', 'tagServices', 'tagPurchaseInvoices',]
        });



        if (taskId != null) {
            task.fetch();
        }

        showModal(ModalEditWrapper, {
            content: (
                <TaskEdit task={task} afterSave={() => {
                    hideModal();
                    afterSave();
                }}></TaskEdit>
            ),
        })
    }

    hide() {
        const { hideSidebarContent } = this.props;
        hideSidebarContent()
    }

    render() {
        return (
            <TasksContainer>
                {/* header */}
                <div>
                    {/* top */}
                    <TopSection>
                        <ContentTitle>{t('task.sidebar.title')}</ContentTitle>
                        <BlueLink to='/account/task/overview' onClick={() => this.hide()}>{t('task.sidebar.openOverview')}</BlueLink>
                    </TopSection>
                    {/* form */}
                    <FormContainer>
                        <TargetSelect multiple
                            name=".status:in"
                            store={this.taskStore}
                            options={TASK_STATUSES.map((status) => ({
                                value: status,
                                text: t(`statuses.${status}`),
                            }))}
                            afterChange={(values) => this.setStatusFilter(values)}
                        />
                    </FormContainer>

                    {/* buttons */}
                    <CustomToolbar>
                        <Button primary compact icon
                            labelPosition='left'
                            onClick={() => this.openTaskModal()}>
                            <Icon name='add' />
                            {t('task.create.title')}
                        </Button>
                    </CustomToolbar>
                </div>
                {/* list */}
                <TasksList>
                    {/* for each task render task item */}
                    {this.taskStore.models.map((task) => (
                        <StyledTask color={getStatusColor(task.status)}>
                            <StyledTaskTitle>{task.subject}</StyledTaskTitle>
                            <StyledTaskDescription>{task.description}</StyledTaskDescription>
                            <StyledTaskCreated>{task.createdAt.toFormat(DATETIME_FORMAT_PRETTY)} {t('task.sidebar.createdBy')} {task.createdBy?.fullName}</StyledTaskCreated>
                            <StyledTaskButtons>
                                <span>
                                    {/* [TODO] currently mark as read not supported, so dummy span added */}
                                    {/* <BlueLink disabled>Mark as read</BlueLink> */}
                                </span>
                                <BlueLink onClick={() => this.openTaskModal(task.id)}><Icon name='edit outline' />{t('task.sidebar.editTask')}</BlueLink>
                            </StyledTaskButtons>
                        </StyledTask>
                    ))}
                </TasksList>
            </TasksContainer>
        )
    }
}