import { DATETIME_FORMAT_SHORT } from 'helpers';
import { observable, action, computed } from 'mobx';
import { Model, Store, Casts } from 'store/Base';

export class TransicsPosition extends Model {
    static backendResourceName = 'transics_position';

    @observable id = null;
    @observable dataExternalID = null;
    @observable point = { lng: '', lat: '' };
    @observable truck = null;
    @observable trailer = null;
    @observable measuredAt = null;
    @observable odometer = 0;

    // Filled in from Fleetvisor.
    @observable closestCity = '';
    @observable closestStreet = '';
    @observable closestCountry = '';

    @observable _address = '';
    @observable _street = '';
    @observable _city = '';
    @observable _country = '';
    @observable _prevLocationAddress = '';

    casts() {
        return {
            measuredAt: Casts.datetime,
        };
    }

    /**
     * Convert to something that a Loction can use. Assumes this Position
     * is already geocoded.
     */
    toLocation() {
        return {
            address: `${this._street}, ${this._city}, ${this._country}`,
            city: this._city,
            country: this._country,
            point: {
                lat: this.point.lat,
                lng: this.point.lng,
            },
        };
    }

    toLocationClosest() {
        return {
            address: this.closestAddress,
            city: this.closestCity,
            country: this.closestCountry,
            point: {
                lat: this.point.lat,
                lng: this.point.lng,
            },
        };
    }

    @computed get closestAddress() {
        return `${this.closestStreet} ${this.closestCity} (${this.closestCountry})`;
    }

    @computed get latLng() {
        return `${this.point.lat}, ${this.point.lng}`;
    }

    toStringExtended() {
        return `${this.closestCity} ( ${this.latLng} ) ${t('general.at')} ${this.measuredAt.toFormat(DATETIME_FORMAT_SHORT)}`;
    }

    // {geocode-copy-pasta}
    geocode() {
        this.__pendingRequestCount += 1;

        return this.api
            .post('/location/geocode/',
                { address: `${this.point.lat}, ${this.point.lng}` },
                { skipRequestError: true }
            )
            .then(action(response => {
                this._city = response.data.city;
                this._address = response.data.address;
                this._street = response.data.street;
                this._country = response.data.country;
                this.__pendingRequestCount -= 1;

                return response;
            }))
            .catch(err => {
                this.__pendingRequestCount -= 1;
                throw err;
            });
    }
}

export class TransicsPositionStore extends Store {
    Model = TransicsPosition;
    static backendResourceName = 'transics_position';

    comparator(a, b) {
        if (a.measuredAt > b.measuredAt) {
            return -1;
        }

        if (a.measuredAt < b.measuredAt) {
            return 1;
        }

        return 0;
    }

    lastPosition() {
        if (this.length > 0) {
            return this.at(0);
        }
    }
}

