export const READY = 'ready';
export const NOT_READY = 'not_ready';
export const UNKNOWN = 'unknown';
export const CUSTOMS_STATUS = [READY, NOT_READY, UNKNOWN];

export default {READY, NOT_READY, UNKNOWN}

export function getStatusColor(status) {
    switch (status) {
        case READY:
            return 'var(--green-600)'
        case NOT_READY:
            return 'var(--red-600)'
        case UNKNOWN:
            return 'var(--gray-300)'
        default:
            return 'var(--white)';
    }
}