import { Model, Store } from 'store/Base';
import { observable } from 'mobx';
import { SalesPlanStore } from './SalesPlan';
import { TruckStore } from './Truck';
import { TrailerStore } from './Trailer';
import { User } from './User';
import AssetType from 'store/enums/AssetType.js';

export class UserFlags extends Model {
    static backendResourceName = 'user_flags';

    @observable id = null;

    toggleFlag = (type, id) => {
        this.setFlag(type, id, !this.getFlag(type, id))
    }

    getStore = (type) => {
        let store = {}
        switch (type) {
            case AssetType.TRUCK:
                store = this.flaggedTrucks
                break;
            case AssetType.TRAILER:
                store = this.flaggedTrailers
                break;
            case AssetType.SALES:
                store = this.flaggedSalesPlan
                break;
            default:
                return;
        }
        return store
    }

    setFlag = (type, id, value) => {
        return this.api.post(`${this.url}set_flag/`, { type, id, value })
            .then(() => {
                let store = this.getStore(type)
                if (value) {
                    store.add({ id })
                } else {
                    store.removeById(id)
                }
            })
    }

    getFlag = (type, id) => {
        let flag = null;
        switch (type) {
            case AssetType.TRUCK:
                flag = this.flaggedTrucks?.find((truck) => truck.id === id);
                break;
            case AssetType.TRAILER:
                flag = this.flaggedTrailers?.find((trailer) => trailer.id === id);
                break;
            case AssetType.SALES:
                flag = this.flaggedSalesPlan?.find((sale) => sale.id === id);
                break;
            default:
                return;
        }
        return flag != null
    }

    flagAll = (filterStore, type) => {
        let ids = filterStore.map((obj)=>obj.id)

        let markFlags = this.areAllFlagged(type, ids);
        return this.api.post(`/user_flags/set_all_flags/`, { type, ids, markFlags, userFlagId: this.id})
        .then(() => {
            let store = this.getStore(type)
            if (ids) {
                store.add({ ids })
            }
        })
    }

    areAllFlagged = (type, ids) => {
        let isFlagged = '';
        switch (type) {
            case AssetType.TRUCK:
                isFlagged = ids.every(v => this.flaggedTrucks?.find((truck) => truck.id === v));
                break;
            case AssetType.TRAILER:
                isFlagged = ids.every(v => this.flaggedTrailers?.find((trailer) => trailer.id === v));
                break;
            case AssetType.SALES:
                isFlagged = ids.every(v => this.flaggedSalesPlan?.find((sales) => sales.id === v));
                break;
            default:
                return;
        }

        return isFlagged;
    }


    relations() {
        return {
            user: User,
            flaggedTrucks: TruckStore,
            flaggedTrailers: TrailerStore,
            flaggedSalesPlan: SalesPlanStore
        };
    }
}

export class UserFlagsStore extends Store {
    Model = UserFlags;
    static backendResourceName = 'user_flags';
}
