import { Model, Store } from 'store/Base';
import { observable } from 'mobx';

export class PurchaseInvoiceDocument extends Model {
    static backendResourceName = 'purchase_invoice_document';

    @observable id = null;
    @observable file = null;
    @observable description = '';
}

export class PurchaseInvoiceDocumentStore extends Store {
    Model = PurchaseInvoiceDocument;
    static backendResourceName = 'purchase_invoice_document';
}
