import { Model, Store, Casts } from 'store/Base';
import { observable, computed } from 'mobx';
import { Customer } from './Customer';
import { InvoiceLineStore } from './InvoiceLine';
import InvoiceStatus from './enums/InvoiceStatus';
import { CURRENCY_EURO, CURRENCY_POUND } from './enums/InvoiceCurrency';
import {DateTime} from 'luxon';
import { trimEnd } from 'lodash';
import { DocumentStore } from './Document';
import { formatMoneyEuro, formatMoneyPound } from 'helpers';
import { uniqBy } from 'lodash';
import { TYPE_INVOICE } from './enums/ContactTypes';
import { ContactStore } from './Contact';
import { Email } from './Email';
import _ from 'lodash';

export class Invoice extends Model {
    static backendResourceName = 'invoice';

    @observable id = null;
    @observable invoiceNumber = '';
    // @observable currency = CURRENCY_EURO;
    @observable currency;
    @observable status = InvoiceStatus.DRAFTED;

    @observable reference = '';
    @observable totalNetPrice = '';
    @observable vat = '';
    @observable totalPrice = '';
    @observable notes = '';
    @observable remarks = '';
    @observable ledgerCode = '';
    @observable isCredit = false;

    @observable invoiceDate = DateTime.now();
    @observable draftSentAt = null
    @observable draftSentTo = [];
    @observable createdAt = null;
    @observable updatedAt = null;

    @observable draftAcceptanceUuid = null;
    @observable hasTasks = false;


    // Private
    _contactStore = new ContactStore();


    relations() {
        return {
            customer: Customer,
            creditOf: Invoice,
            lines: InvoiceLineStore,
            documents: DocumentStore,
        };
    }

    casts() {
        return {
            invoiceDate: Casts.datetime,
            draftSentAt: Casts.datetime,
            createdAt: Casts.datetime,
            updatedAt: Casts.datetime,
        };
    }

    @computed get tagLabel() {
        return `I${this.invoiceNumber}`;
    }

    @computed get editUrl() {
        return `/admin/invoice/${this.id}/edit`;
    }

    get statusColorOverview() {
        switch (this.status) {
            case InvoiceStatus.SENT:
                return 'var(--orange-300)'
            case InvoiceStatus.PAID:
                return 'var(--green-300)'
            case InvoiceStatus.OVERDUE:
                return 'var(--red-300)';
            case InvoiceStatus.ERROR:
                return 'var(--red-300)';
            default:
                return 'var(--blue-300)';
        }
    }


    get statusColorSemantic() {
        switch (this.status) {
            case InvoiceStatus.SENT:
                return 'orange'
            case InvoiceStatus.PAID:
                return 'green'
            case InvoiceStatus.OVERDUE:
                return 'red';
            case InvoiceStatus.ERROR:
                return 'red';
            default:
                return 'blue';
        }
    }

    getOrderReference() {
        const invoiceLine = uniqBy(this.lines.models, line => line.order.invoiceReference);

        if (invoiceLine.length === 1) {
            return invoiceLine.at(0).order.invoiceReference;
        } else {
            return ''
        }
    }

    convertToCredit() {
        return this.api.get(`${this.url}credit_invoice/`)
    }

    @computed
    get invoiceCostsAmount() {
        return this.lines.models.reduce((sum, line) => sum + line.total, 0);
    }

    @computed
    get draftPdfUrl() {
        return `${trimEnd(this.api.baseUrl, '/')}${this.url}draft_pdf/${this.draftAcceptanceUuid}/`;
    }

    @computed
    get draftPdfDownloadUrl() {
        return `${this.draftPdfUrl}?download=true`;
    }

    @computed get formattedTotalPrice() {
        if (this.currency === CURRENCY_EURO) {
            return formatMoneyEuro(this.totalPrice);
        } else if (this.currency === CURRENCY_POUND) {
            return formatMoneyPound(this.totalPrice);
        }

        // throw new Error(`Unknown currency: ${this.currency}`);
    }

    @computed
    get resendUrl() {
        return this.api.post(`${this.url}resend/`)
    }

    getContactStore() {
        this._contactStore.params = {
            '.type': TYPE_INVOICE,
            '.email_address:not': '',
            '.customer.id': this.customer?.id,
        }
        return this._contactStore
    }

    async getEmail() {
        const { fileSize } = await this.checkDocumentsSize();
        this.getOrderPodDocuments().forEach(x=>x.fileSize = fileSize[x.id]);

        return new Email().generate(
            `Invoice ${this.invoiceNumber} regarding ${this.reference}`,
            `Dear Customer team, \n` +
            `Please find attached our invoice ${this.invoiceNumber}, regarding your transport ${this.reference}. \n` +
            `If you have any questions, do not hesitate to contact us. \n\n` +
            `Kind Regards, \n` +
            `The Dalessi Team \n` +
            `administratie@dalessi.nl`,
            [],
            this.getOrderPodDocuments()
        )
    }

    getAllOrderDocument() {
        let arrDocument = []
        if (this.lines.length) {
            uniqBy(this.lines.models, line => line.order.id).map(line => {
                if (line?.order?.documents?.length) {
                    line.order.documents.map(doc => (
                        arrDocument.push(doc)
                    ))
                }
                return arrDocument;
            })
        }

        return arrDocument;
    }

    getAttachmentDocuments() {
        return this.getAllOrderDocument().filter(doc => doc.type !== 'proof_of_delivery' && doc.type !== 'invoice')
    }

    getOrderDocuments() {
        return this.getAllOrderDocument().filter(doc => doc.type !== 'proof_of_delivery')
    }

    getOrderPodDocuments() {
        return this.getAllOrderDocument().filter(doc => doc.type === 'proof_of_delivery')
    }

    checkDocumentsSize() {
        return this.api.get(`${this.url}documents_size/`)
    }

    getSuggestedInvoiceDate() {
        const currentDate = DateTime.now();
        let activityDates = []
        if (this.lines.length) {
            uniqBy(this.lines.models, line => line.order.id).map(line => {
                const lastActivity = line?.order?.lastActivity;
                if (lastActivity && lastActivity.orderedArrivalDatetimeFrom && lastActivity.orderedArrivalDatetimeUntil) {
                    activityDates.push(lastActivity.orderedArrivalDatetimeFrom);
                    activityDates.push(lastActivity.orderedArrivalDatetimeUntil);
                }

                return activityDates;
            });
        }

        activityDates = activityDates.sort((a, b) => a - b);

        if (activityDates.length) {
            const date = activityDates.at(-1);

            var previousMonth = (((currentDate.year - date.year) * 12 + currentDate.month) - date.month) > 0;

            if (previousMonth) {
                const lastDay = DateTime.utc(date.year, date.month).daysInMonth;

                return DateTime.utc(date.year, date.month, lastDay);
            }
        }

        return currentDate;
    }

    setSuggestedInvoiceDateInput = () => {
        if (this.status === InvoiceStatus.DRAFTED) {
            const invoiceDate = this.getSuggestedInvoiceDate();
            this.setInput('invoiceDate', invoiceDate);
        }
    }

    async getByInvoiceNumber(invoiceNumber) {
        const invoiceStore = new InvoiceStore({
            params: {
                '.invoice_number': invoiceNumber,
            },
            relations: this.__activeRelations,
        });

        await invoiceStore.fetch();
        if (invoiceStore.models.length > 0) {
            return invoiceStore.models[0];
        } else {
            return null
        }
    }

    @computed get pdfPreviewUrl() {
        return `/api${this.url}pdf/`;
    }

    @computed get pdfDownloadUrl() {
        return `${this.pdfPreviewUrl}?download=true`;
    }

    @computed get attachmentName() {
        return `Invoice-${this.invoiceNumber}.pdf`;
    }

    @computed get multipleOrders() {
        return _.uniq(this.lines?.models.map(l => l.order.id)).length > 1;
    }
    
    @computed get distinctInvoiceLine() {
        const invoiceLine = uniqBy(this.lines?.models, line => line.order.id);

        if (invoiceLine.length > 0) {
            return invoiceLine;
        } else {
            return []
        }
    }

    getAttachmentInfo() {
        const invoiceAttachment = {
            id: `E${this.id}`,
            url: this.pdfDownloadUrl,
            previewUrl: this.pdfPreviewUrl,
            name: this.attachmentName,
            optional: false,
        }

        const documentsPODs = this.getOrderPodDocuments().map(doc => {
            return {
                id: `D${doc.id}`,
                url: doc.downloadUrl,
                previewUrl: doc.previewUrl,
                name: doc.name,
                optional: true,
                checked: true,
            }
        });

        const documents = this.getAttachmentDocuments().map(doc => {
            return {
                id: `D${doc.id}`,
                url: doc.downloadUrl,
                previewUrl: doc.previewUrl,
                name: doc.name,
                optional: true,
            }
        });

        return [invoiceAttachment, ...documentsPODs, ...documents];
    }
}

export class InvoiceStore extends Store {
    Model = Invoice;
    static backendResourceName = 'invoice';
}
