import { DATETIME_FORMAT_SHORT, TIME_FORMAT } from 'helpers';
import { DateTime, Duration } from 'luxon';
import { observable, computed } from 'mobx';
import { Model, Store, Casts } from 'store/Base';
import TransicsETAStatus from './enums/TransicsETAStatus';


export function getEtaStringSimple(eta) {
    if (eta == null) {
        return 'C'
    }
    
    const etaDiff = eta?.diff(DateTime.now(), ["hours", "minutes"])

    if (etaDiff == null || etaDiff < 0) {
        return 'O';
    }
    if (!eta.isValid) {
        return 'I';
    }
    return `${eta.toFormat(TIME_FORMAT)}`;
}

export class TransicsETA extends Model {
    static backendResourceName = 'transics_eta';

    @observable id = null;
    @observable dataExternalID = null;
    @observable positionDestination = { lng: '', lat: '' };
    @observable truck = null;
    @observable measuredAt = null;
    @observable distance = 0;

    @observable eta = null;
    @observable prevEta = null;
    @observable restIncluded = null;

    @observable status = TransicsETAStatus.ABORTED;
    @observable positionInfoDestination = '';

    casts() {
        return {
            measuredAt: Casts.datetime,
            prevEta: Casts.datetime,
            eta: Casts.datetime,
            restIncluded: Casts.datetime,
        };
    }

    @computed get etaDiff() {
        return this.getEtaTimeDiff(this.eta)
    }

    @computed get restEtaDiff() {
        return this.getEtaTimeDiff(this.restIncluded)
    }

    @computed get etaDiffFormatted() {
        const diff = this.etaDiff;
        if (diff == null || diff < 0) {
            return '00:00';
        }

        return Duration.fromObject(diff.toObject()).toFormat('hh:mm');
    }

    @computed get stringSimple() {
        return getEtaStringSimple(this.eta);
    }

    @computed get stringRestEtaSimple() {
        return getEtaStringSimple(this.restIncluded);
    }

    @computed get stringExtended() {
        // Since switched to next stop info, those seems not relevant anymore
        // Status: ${this.status}
        // Prev ETA: ${this.prevEta?.isValid ? this.prevEta?.toFormat(DATETIME_FORMAT_SHORT) : '--'}
        return `
        ${t(`eta.fields.header`)}: ${this.eta?.isValid ? this.eta?.toFormat(DATETIME_FORMAT_SHORT) : '--'} (${this.etaDiffFormatted})
        ${t(`eta.fields.rest`)}: ${this.restIncluded?.isValid ? this.restIncluded.toFormat(DATETIME_FORMAT_SHORT) : '--'}
        ${t(`eta.fields.distance`)}: ${this.distance}
        ${t(`eta.fields.destination`)}: ${this.positionInfoDestination}
        ${t(`eta.fields.id`)}: ${this.id} ${t('general.at')} ${this.measuredAt.toFormat(DATETIME_FORMAT_SHORT)}`;
    }

    toStringExtended() {
        return `${this.closestCity} ( ${this.latLng} ) ${t('general.at')} ${this.measuredAt.toFormat(DATETIME_FORMAT_SHORT)}`;
    }

    @computed get latLng() {
        return `${this.positionDestination.lat}, ${this.positionDestination.lng}`;
    }

    getEtaTimeDiff(eta) {
        return eta?.diff(DateTime.now(), ["hours", "minutes"])
    }
}

export class TransicsETAStore extends Store {
    Model = TransicsETA;
    static backendResourceName = 'transics_eta';

    comparator(a, b) {
        if (a.measuredAt > b.measuredAt) {
            return -1;
        }

        if (a.measuredAt < b.measuredAt) {
            return 1;
        }

        return 0;
    }

    lastETA() {
        if (this.length > 0) {
            return this.at(0);
        }
    }
}

