import { Model, Store } from 'store/Base';
import { observable } from 'mobx';
import { TruckingCompanyTariffStore } from './TruckingCompanyTariff';
import { TruckingCompany } from './TruckingCompany';

export class TruckingCompanyContract extends Model {
    static backendResourceName = 'trucking_company_contract';

    /**
     * Trucking companies might have multiple contracts. With different trucks that drive for different rates.
     */

    @observable id = null;
    @observable name = '';

    relations() {
        return {
            truckingCompany: TruckingCompany,
            tariffs: TruckingCompanyTariffStore,
        };
    }
}

export class TruckingCompanyContractStore extends Store {
    Model = TruckingCompanyContract;
    static backendResourceName = 'trucking_company_contract';
}
