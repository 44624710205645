export const DRAFT = 'draft';
export const SENT = 'sent';
export const CANCELED = 'canceled';
export const ORDER_SUBCONTRACT_STATUSES = [DRAFT, SENT, CANCELED]

export default { DRAFT, SENT, CANCELED }

export function getStatusColor(status) {
    switch (status) {
        case CANCELED:
            return 'var(--red-50)'
        case DRAFT:
            return 'var(--orange-50)'
        case SENT:
            return 'var(--green-50)'
        default:
            return 'var(--gray-100)';
    }
}

export function getStatusBorderColor(status) {
    switch (status) {
        case CANCELED:
            return 'var(--red-600)'
        case DRAFT:
            return 'var(--orange-600)'
        case SENT:
            return 'var(--green-600)'
        default:
            return 'var(--gray-100)';
    }
}
