import { observable, computed } from 'mobx';
import { Model, Store, Casts } from 'store/Base';
import { Truck } from './Truck';
import { TransicsPosition } from './TransicsPosition';
import { Activity } from './Activity';
import { omit } from 'lodash';

export const TYPE_TEXT = 'text';
export const TYPE_FORM = 'form';
export const TYPE_ACTIVITY = 'activity';
export const TYPE_IMAGE = 'image'; 
export const TYPE_AUDIO = 'audio';
export const TYPE_FILE = 'file';
export const TYPES = [TYPE_TEXT, TYPE_FORM, TYPE_ACTIVITY, TYPE_IMAGE, TYPE_AUDIO, TYPE_FILE];

export const SOURCE_PLANNER = 'planner';
export const SOURCE_DRIVER = 'driver';
export const SOURCES = [SOURCE_PLANNER, SOURCE_DRIVER];

export class Message extends Model {
    static backendResourceName = 'message';

    @observable id = null;
    @observable text = '';
    @observable source = SOURCE_PLANNER;
    @observable seenByPlannerAt = null;
    @observable seenByDriverAt = null;
    @observable writtenAt = null;
    @observable receivedAt = null;
    @observable sendAfter = null;
    @observable expectedSendDatetime = null;
    @observable type = TYPE_TEXT;
    @observable driverActivityStatus = null;
    @observable dataSource = null;
    @observable dataExternalFormId = null;
    @observable dataExternalRouteId = null;
    @observable jobUuid = null;
    @observable file = null;

    casts() {
        return {
            writtenAt: Casts.datetime,
            receivedAt: Casts.datetime,
            expectedSendDatetime: Casts.datetime,
            sendAfter: Casts.datetime,
            seenByPlannerAt: Casts.datetime,
            seenByDriverAt: Casts.datetime,
        };
    }

    relations() {
        return {
            truck: Truck,
            activity: Activity,
            truckPosition: TransicsPosition,
        };
    }

    markRead(updateField = true) {
        // return this.api
        //     .post('/message/mark_read_planner/', {
        //         id: this.id,
        //     })
        //     .then(() => {
        //         if (updateField) {
        //             this.seenByPlannerAt = DateTime.utc();
        //         }
        //     });
    }

    markUnread() {
        this.__pendingRequestCount++;

        return this.api
            .post('/message/mark_unread_planner/', {
                id: this.id,
            })
            .then(() => {
                this.seenByPlannerAt = null;
                this.__pendingRequestCount--;
            })
            .catch(() => {
                this.__pendingRequestCount--;
            });
    }

    toBackend(...args) {
        const data = super.toBackend(...args);

        return omit(data, 'expected_send_datetime');
    }
}

export class MessageStore extends Store {
    Model = Message;
    static backendResourceName = 'message';
    comparator = 'expectedSendDatetime';

    markRead(truckId, types, unreadMessagesByTruckStore) {
        // [TODO] Temporary disable this feature as Lipno uses original transics to mark it
        // Note we don't set `seenByPlannerAt` here on purpose; this way we can show the unread messages with bold text
        // return api
        //     .post('/message/mark_read_planner/', {
        //         truck: truckId,
        //         type: types,
        //     })
        //     .then(() => {
        //         const unreadMessagesByTruck = unreadMessagesByTruckStore.get(truckId);

        //         if (unreadMessagesByTruck) {
        //             types.forEach(type => {
        //                 unreadMessagesByTruck[type] = 0;
        //             });
        //         }
        //     });
    }

    markReadById(id) {
        // Note we don't set `seenByPlannerAt` here on purpose; this way we can show the unread messages with bold text
        
        // [TODO] Temporary disable this feature as Lipno uses original transics to mark it
        // return api.post('/message/mark_read_planner/', {
        //     id: id,
        // });
    }

    markFormAsRead = (truckId, unreadByTruck) => {
        // [TODO] Temporary disable this feature as Lipno uses original transics to mark it
        // Only for `form` messages we immediately want to update the `seenByPlannerAt`, so the ignore button can be removed
        // this.markRead(truckId, ['form'], unreadByTruck);
        // this.each(
        //     action(message => {
        //         if (message.type === 'form' && !message.seenByPlannerAt) {
        //             message.seenByPlannerAt = DateTime.utc();
        //         }
        //     })
        // );
    };
}

export class UnreadMessagesByTruck extends Model {
    @observable id = null; // This is the truck id.
    @observable text = 0;
    @observable form = 0;
    @observable route = 0;
    @observable activity = 0;

    @computed
    get count() {
        return this.text + this.form + this.activity;
    }

    /**
     * Mark message as read. Basically lowers unread count by 1.
     *
     * See T18134.
     */
    markRead(message) {
        if (this[message.type] > 0) {
            this[message.type]--;
        }
    }
}

// Jup, this is not a mobx-spine store because it doesn't need any mobx-spine stuff
// ^ FU, this is confusing as hell. So everything is a store, but not this?
// Really, this sucks so much and there are so many bugs... Can't even...
// Again, so many bugs, because this does not work as expected... Seriously,
// do you even code?
export class UnreadMessagesByTruckStore extends Store {
    Model = UnreadMessagesByTruck;
    url = 'message/unread_planner/'

    /**
     * Item looks like:
     *
     * - {unread: "+1", truck: 254, type: "text"}
     * - {unread: "+1", truck: 297, type: "form"}
     * - {unread: "+1", truck: 29, type: "activity"}
     *
     */
    updateFromSocket(item) {
        const truckId = item.truck;
        const type = item.type;
        const count = parseInt(item.unread);

        const unreadMessagesByTruck = this.get(truckId)
            ? this.get(truckId)
            : this.add({ id: truckId });

        unreadMessagesByTruck[type] += count;
    }

    handlePublish(msg) {
        this.updateFromSocket(msg.data);
    }
}
