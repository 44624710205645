import { Model, Store } from 'store/Base';
import { observable, computed } from 'mobx';
import { TruckingCompany } from 'store/TruckingCompany';
import { PurchaseInvoiceSelfBillTripActivityLineStore } from 'store/PurchaseInvoiceSelfBillTripActivityLine';
import { PurchaseInvoice } from './PurchaseInvoice';
import { DAILY_RATE, OTHER_COSTS } from './enums/CorrectionType';

export class PurchaseInvoiceSelfBill extends Model {
    static backendResourceName = 'purchase_invoice_self_bill';

    @observable id = null;

    @computed
    get invoiceAmountComputed() {
        return this.tripActivityLines?.map(line => line.amount).reduce((a, b) => a + b, 0);
    }

    @computed get correctionTotal() {
        return this.tripActivityLines?.map(line => line.corrections).reduce((a, b) => a + b?.models.reduce((a, b) => a + b.amount, 0), 0);
    }

    @computed get correctionTotalDailyRate() {
        return this.tripActivityLines?.map(line => line.corrections).reduce((a, b) => a + b?.models.filter(line => line.type === DAILY_RATE).reduce((a, b) => a + b.amount, 0), 0);
    }

    @computed get correctionTotalOther() {
        return this.tripActivityLines?.map(line => line.corrections).reduce((a, b) => a + b?.models.filter(line => line.type === OTHER_COSTS).reduce((a, b) => a + b.amount, 0), 0);
    }

    relations() {
        return {
            purchaseInvoice: PurchaseInvoice,
            truckingCompany: TruckingCompany,
            tripActivityLines: PurchaseInvoiceSelfBillTripActivityLineStore,
        };
    }
}

export class PurchaseInvoiceStore extends Store {
    Model = PurchaseInvoiceSelfBill;
    static backendResourceName = 'purchase_invoice_self_bill';
}
