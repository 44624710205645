import { Casts, Model, Store } from 'store/Base';
import { observable, computed } from 'mobx';
import { Location } from './Location';
import { DocumentStore } from './Document';
import { TruckStore } from './Truck';
import { TrailerStore } from './Trailer';
import { TruckingCompanyContractStore } from './TruckingCompanyContract';
import { ContactStore } from './Contact';
import { TruckingCompanyTariffStore } from './TruckingCompanyTariff';

export class TruckingCompany extends Model {
    static backendResourceName = 'trucking_company';

    @observable id = null;
    @observable name = '';
    @observable emailAddress = '';
    @observable phone = '';

    @observable specialization = '';
    @observable iban = '';
    @observable swiftBic = '';

    @observable chamberOfCommerce  = '';
    @observable vatCode = '';

    @observable paymentTerms = 60;
    @observable colorCompany = '#BBDEFB';

    @observable signedAgreementExpiryDate = null;
    @observable liabilityInsuranceExpiryDate = null;
    @observable licensesExpiryDate = null;
    @observable letterheadExpiryDate = null;
    @observable euLicenseForGoodsTransportationExpiryDate = null;
    @observable cmrInsurancePolicyExpiryDate = null;
    @observable currency = null;

    @observable contact;
    @observable subcontractor = false;
    @observable blacklisted = false;
    @observable blacklistedReason = '';
    @observable priority = 1;
    @observable creditorNumber = null;


    @computed get address() {
        let houseNumber = '';
        if (this.location.houseNumber > 0) {
            houseNumber =  ' ' + this.location.houseNumber
        }
        return this.location.street + houseNumber;
    };
    @computed get city() {
        return this.location.city;
    };
    @computed get country() {
        return this.location.country;
    };
    @computed get geoLocation() {
        return this.location.point;
    };
    @computed get hasContact() {
        return this.contacts?.models.length === 0;
    };
    @computed get emailContacts() {
        return this.contacts.models.map((contact) => contact.emailAddress);
    }

    fetchActiveTariff() {
        const truckingCompanyTariffStore = new TruckingCompanyTariffStore({ params: {
            '.contract.trucking_company': this.id,
            limit: 1,
            order_by: '-id',
        }});

        return truckingCompanyTariffStore.fetch().then(() => {
            if (truckingCompanyTariffStore.length === 1) {
                return truckingCompanyTariffStore.at(0);
            }
        });
    }

    relations() {
        return {
            contracts: TruckingCompanyContractStore,
            documents: DocumentStore,
            location: Location,
            trucks: TruckStore,
            trailers: TrailerStore,
            contacts: ContactStore,
        };
    }

    casts() {
        return {
            signedAgreementExpiryDate: Casts.datetime,
            liabilityInsuranceExpiryDate: Casts.datetime,
            licensesExpiryDate: Casts.datetime,
            letterheadExpiryDate: Casts.datetime,
            euLicenseForGoodsTransportationExpiryDate: Casts.datetime,
            cmrInsurancePolicyExpiryDate: Casts.datetime,
        };
    }
}

export class TruckingCompanyStore extends Store {
    Model = TruckingCompany;
    static backendResourceName = 'trucking_company';
}
