import { api, parseRegisterCancelToken } from 'store/Base';
const optimizationLevelPtv = 1;
const _ = require("lodash");

export async function fetchRouteDistances(routeLocations) {
    const promises = [];

    for (let i = 0; i < routeLocations.length; i++) {
        promises.push(fetchRouteDistance(routeLocations[i][0], routeLocations[i][1]));
    };

    return (await Promise.all(promises)).map((element) => element.distance);
}

export const fetchRouteDistance = _.memoize(async (from, to) => {
    const options = Object.assign({
        optimizationLevel: optimizationLevelPtv,
        hazardousGoods: false,
        euroClass: 5,
    });

    return calculateRouteDistance([from, to].map(_mapLocation), options);
}, (from, to) => `${from.point.lat}-${from.point.lng},${to.point.lat}-${to.point.lng}`)

export function calculateRoute(locations, options = {}, extraRequestOptions = {}) {
    extraRequestOptions = parseRegisterCancelToken(extraRequestOptions);

    // Yeah, don't know how to do this as default argument.
    // The useRealtimeInfo value is a dirty hack to make the cypress tests function properly,
    // window.noRealtimeRoutes will be set to true in some cypress tests.
    const mergedOptions = { optimizationLevel: optimizationLevelPtv, useRealtimeInfo: !window['noRealtimeRoutes'], hazardousGoods: false, euroClass: 5 };
    Object.assign(mergedOptions, options);
    const requestOptions = {
        locations,
        'optimization_level': mergedOptions.optimizationLevel,
        'use_realtime_info': mergedOptions.useRealtimeInfo,
        'hazardous_goods': mergedOptions.hazardousGoods,
        'euro_class': mergedOptions.euroClass,
    };

    return new Promise((resolve, reject) => {
        api.post(
            '/location/calculate_route/',
            requestOptions,
            {
                skipRequestError: true,
                ...extraRequestOptions,
            }
        ).then(res => {
            resolve({ ...res, useRealtimeInfo: mergedOptions.useRealtimeInfo });
        }).catch(e => {
            // Fail? Try again without realtime info, as there may
            // be a road block right now (but it can be cleared up
            // when we arrive, or it might have not existed when
            // we planned it or drove, but it does exist now).
            // Unfortunately, PTV does not tell us if that's the
            // case so we have to blindly retry.
            new Promise(() => {
                api.post(
                    '/location/calculate_route/',
                    Object.assign({ use_realtime_info: false }, requestOptions),
                    {
                        skipRequestError: true,
                        ...extraRequestOptions,
                    }
                )
                    .then(res => resolve({ ...res, useRealtimeInfo: false }))
                    .catch(res => reject(res));
            });
        });
    });
}

export function calculateRouteDistance(locations, options = {}, extraRequestOptions = {}) {
    extraRequestOptions = parseRegisterCancelToken(extraRequestOptions);

    // Yeah, don't know how to do this as default argument.
    // The useRealtimeInfo value is a dirty hack to make the cypress tests function properly,
    // window.noRealtimeRoutes will be set to true in some cypress tests.
    const mergedOptions = { optimizationLevel: optimizationLevelPtv, useRealtimeInfo: !window['noRealtimeRoutes'], hazardousGoods: false, euroClass: 5 };
    Object.assign(mergedOptions, options);
    const requestOptions = {
        locations,
        'optimization_level': mergedOptions.optimizationLevel,
        'use_realtime_info': mergedOptions.useRealtimeInfo,
        'hazardous_goods': mergedOptions.hazardousGoods,
        'euro_class': mergedOptions.euroClass,
    };

    return new Promise((resolve, reject) => {
        api.post(
            '/location/calculate_distance/',
            requestOptions,
            {
                skipRequestError: true,
                ...extraRequestOptions,
            }
        ).then(res => {
            resolve({ ...res, useRealtimeInfo: mergedOptions.useRealtimeInfo });
        }).catch(e => {
            // Fail? Try again without realtime info, as there may
            // be a road block right now (but it can be cleared up
            // when we arrive, or it might have not existed when
            // we planned it or drove, but it does exist now).
            // Unfortunately, PTV does not tell us if that's the
            // case so we have to blindly retry.
            new Promise(() => {
                api.post(
                    '/location/calculate_distance/',
                    Object.assign({ use_realtime_info: false }, requestOptions),
                    {
                        skipRequestError: true,
                        ...extraRequestOptions,
                    }
                )
                    .then(res => resolve({ ...res, useRealtimeInfo: false }))
                    .catch(res => reject(res));
            });
        });
    });
}

function _mapLocation(location) {
    return {
        lat: location.point.lat,
        lng: location.point.lng,
    }
}
