export const CURRENCY_EURO = 'euro';
export const CURRENCY_POUND = 'pound';
export const CURRENCIES = [CURRENCY_EURO, CURRENCY_POUND];
export const CURRENCY_FLAG = {
    euro: 'eu',
    pound: 'gb',
};

export default { CURRENCY_EURO, CURRENCY_POUND }

