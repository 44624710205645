import { Casts, Model, Store } from 'store/Base';
import { computed, observable } from 'mobx';
import { User, UserStore } from './User';
import { HistoryStore, modelWithHistory, storeWithHistory } from './History';
import { OPEN } from './enums/TaskStatus';
import { TruckStore } from './Truck';
import { TrailerStore } from './Trailer';
import { OrderStore } from './Order';
import { InvoiceStore } from './Invoice';
import { BookingStore } from './Booking';
import { ActivityStore } from './Activity';
import { ServiceStore } from './Service';
import { PurchaseInvoiceStore } from './PurchaseInvoice';


export class Task extends Model {
    static backendResourceName = 'task';

    @observable id = null;

    @observable status = OPEN;
    @observable subject = '';
    @observable description = '';

    @observable dueDate = null;

    @observable createdAt = null;
    @observable updatedAt = null;

    @computed get tagLabel() {
        return `TA${this.id}`;
    }

    @computed get editUrl() {
        return `/account/task/${this.id}/edit`;
    }

    @computed get tags() {
        const tags = [];

        tags.push(...this.tagTrucks.models.map(o => {
            return {
                label: o.tagLabel,
                link: o.editUrl
            }
        }))

        tags.push(...this.tagTrailers.models.map(o => {
            return {
                label: o.tagLabel,
                link: o.editUrl
            }
        }))

        tags.push(...this.tagTasks.models.map(o => {
            return {
                label: o.tagLabel,
                link: o.editUrl
            }
        }))

        tags.push(...this.tagOrders.models.map(o => {
            return {
                label: o.tagLabel,
                link: o.editUrl
            }
        }))

        tags.push(...this.tagInvoices.models.map(o => {
            return {
                label: o.tagLabel,
                link: o.editUrl
            }
        }))

        tags.push(...this.tagBookings.models.map(o => {
            return {
                label: o.tagLabel,
                link: o.editUrl
            }
        }))

        tags.push(...this.tagActivities.models.map(o => {
            return {
                label: o.tagLabel,
                link: o.editUrl
            }
        }))

        tags.push(...this.tagServices.models.map(o => {
            return {
                label: o.tagLabel,
                link: o.editUrl
            }
        }))

        tags.push(...this.tagPurchaseInvoices.models.map(o => {
            return {
                label: o.tagLabel,
                link: o.editUrl
            }
        }))

        return tags;
    }

    relations() {
        return {
            createdBy: User,
            assigned: User,
            subscribers: UserStore,

            tagTrucks:  TruckStore,
            tagTrailers:  TrailerStore,
            tagTasks:  TaskStore,
            tagOrders:  OrderStore,
            tagInvoices:  InvoiceStore,
            tagBookings:  BookingStore,
            tagActivities:  ActivityStore,
            tagServices:  ServiceStore,
            tagPurchaseInvoices:  PurchaseInvoiceStore,

            _history: HistoryStore,
        };
    }

    casts() {
        return {
            dueDate: Casts.datetime,
            createdAt: Casts.datetime,
            updatedAt: Casts.datetime,
        };
    }
}

export class TaskStore extends Store {
    Model = Task;
    static backendResourceName = 'task';
}

export class HistoryTask extends modelWithHistory(Task) { };
export class HistoryTaskStore extends storeWithHistory(TaskStore) { };
