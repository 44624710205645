export const DRAFTED = 'drafted';
export const SENT = 'sent';
export const PAID = 'paid';
export const OVERDUE = 'overdue';
export const ERROR = 'error';
export const INVOICE_STATUSES = [DRAFTED, SENT, PAID, OVERDUE, ERROR];

export default { DRAFTED, SENT, PAID, OVERDUE, ERROR }

export function getStatusColorOverview(status) {
    switch (status) {
        case DRAFTED:
            return 'var(--gray-400)'
        case SENT:
            return 'var(--orange-300)'
        case PAID:
            return 'var(--green-300)'
        case OVERDUE:
            return 'var(--blue-300)'
        case ERROR:
            return 'var(--red-300)';
        default:
            return 'var(--gray-400)';
    }
}
