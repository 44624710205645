import React from 'react';
import { DroppableButton as DroppableButtonBase, Button } from '@code-yellow/spider';

// [TODO][SPIDER]
// export * from '@code-yellow/spider';
export { CancelButton, DownloadButton, Icon, Link, SaveButton } from '@code-yellow/spider';

export class DroppableButton extends DroppableButtonBase {
    renderContent(props) {
        const extraProps = {};

        if (this.droppable) {
            extraProps.positive = true;
        } else {
            extraProps.primary = true;
        }

        return (
            <Button {...props} {...extraProps}
                type="button"
                icon="upload"
            />
        );
    }
}
