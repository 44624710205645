import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { View } from '@code-yellow/spider';
import {Route as BaseRoute, Switch, Redirect} from 'react-router-dom';
import * as Sentry from '@sentry/react';
import Load from './Loadable';

// Use the sentry route to enable parameter discovery by react router before
// sending that information to sentry
const Route = Sentry.withSentryRouting(BaseRoute);

const UserOverview = Load(() => import('../screen/User/Overview'));
const UserEdit = Load(() => import('../screen/User/Edit'));
const GlobalValueOverview = Load(() => import('../screen/GlobalValue/Overview'));
const GlobalValueEdit = Load(() => import('../screen/GlobalValue/Edit'));
const GlobalFileOverview = Load(() => import('../screen/GlobalFile/Overview'));
const GlobalFileEdit = Load(() => import('../screen/GlobalFile/Edit'));
const AccessLogEntryOverview = Load(() => import('../screen/AccessLogEntry/Overview'));
const EmailLogOverview = Load(() => import('../screen/User/EmailLog'));

const LoginForgot = Load(async () => ((await import('@code-yellow/spider')).LoginForgot));
const ResetPassword = Load(async () => ((await import('@code-yellow/spider')).ResetPassword));
const AccountDetails = Load(() => import('../screen/AccountDetails'));
const Changelog = Load(() => import('../screen/Changelog'));
const IntegrationLoggerOverview = Load(() => import('../screen/IntegrationLogger/Overview'));
const Unit4Overview = Load(() => import('../screen/Unit4/Overview'));


const NotFound = Load(() => import('../container/NotFound'));

// DAL specific.
const OrderOverview = Load(() => import('../screen/Order/Overview'));
const OrderEdit = Load(() => import('../screen/Order/Edit'));
const BulkOrderEdit = Load(() => import('../screen/Order/BulkEdit'));
const CustomerOverview = Load(() => import('../screen/Customer/Overview'));
const CustomerEdit = Load(() => import('../screen/Customer/Edit'));
const BookingOverview = Load(() => import('../screen/Booking/Overview'));
const BookingEdit = Load(() => import('../screen/Booking/Edit'));
const DistrictOverview = Load(() => import('../screen/District/Overview'));
const GoodsDescriptionOverview = Load(() => import('../screen/GoodsDescription/Overview'));
const ServiceOverview = Load(() => import('../screen/Service/Overview'));
const ServiceEdit = Load(() => import('../screen/Service/Edit'));
const TransporterOverview = Load(() => import('../screen/Transporter/Overview'));
const TransporterEdit = Load(() => import('../screen/Transporter/Edit'));
const DriverOverview = Load(() => import('../screen/Driver/Overview'));
const DriverEdit = Load(() => import('../screen/Driver/Edit'));
const GoodsDescriptionEdit = Load(() => import('../screen/GoodsDescription/Edit'));
const TaskOverview = Load(() => import('../screen/Task/Overview'));
const TaskEdit = Load(() => import('../screen/Task/Edit'));
const TerminalTransporterOverview = Load(() => import('../screen/TerminalTransporter/Overview'));
const TerminalTransporterEdit = Load(() => import('../screen/TerminalTransporter/Edit'));
const CostCalculationOverview = Load(() => import('../screen/CostCalculation/Overview'));
const TerminalEditModal = Load(() => import('./TerminalTransporter/TerminalEditModal'));
const ServiceTypeOverview = Load(() => import('../screen/ServiceType/Overview'));
const ServiceTypeEdit = Load(() => import('../screen/ServiceType/Edit'));

const TrailerOverview = Load(() => import('../screen/Trailer/Overview'));
const TrailerEdit = Load(() => import('../screen/Trailer/Edit'));

const TruckingCompanyContractOverview = Load(() => import('../screen/TruckingCompanyContract/Overview'));
const TruckingCompanyContractEdit = Load(() => import('../screen/TruckingCompanyContract/Edit'));

const TruckOverview = Load(() => import('../screen/Truck/Overview'));
const TruckEdit = Load(() => import('../screen/Truck/Edit'));
const TruckingCompanyOverview = Load(() => import('../screen/TruckingCompany/Overview'));
const TruckingCompanyEdit = Load(() => import('../screen/TruckingCompany/Edit'));

const TripOverview = Load(() => import('../screen/Trip/Overview'));
const TripEdit = Load(() => import('../screen/Trip/Edit'));

const TripActivityOverview = Load(() => import('../screen/TripActivity/Overview'));

const InvoiceOverview = Load(() => import('../screen/Invoice/Overview'));
const InvoiceEdit = Load(() => import('../screen/Invoice/Edit'));

const OrderSubcontractOverview = Load(() => import('../screen/OrderSubcontract/Overview'));
const OrderSubcontractEdit = Load(() => import('../screen/OrderSubcontract/Edit'));

const SalesPlanOverview = Load(() => import('../screen/SalesPlan/Overview'));
const SubcontractingOverview = Load(() => import('../screen/Subcontracting/Overview'));
const CapacityPlanOverview = Load(() => import('../screen/CapacityPlan/Overview'));
const TruckPlanningOverview = Load(() => import('../screen/TruckPlanning/Overview'));

const CommunicationEmailTemplateOverview = Load(() => import('../screen/Communication/EmailTemplate/Overview'));
const CommunicationEmailTemplateEdit = Load(() => import('../screen/Communication/EmailTemplate/Edit'));

const CommunicationActivityStatusEmailTemplateOverview = Load(() => import('../screen/Communication/ActivityStatusEmailTemplate/Overview'));
const CommunicationActivityStatusEmailTemplateEdit = Load(() => import('../screen/Communication/ActivityStatusEmailTemplate/Edit'));

const Unit4Ok = Load(() => import('../screen/Unit4/Ok'))
const Unit4Error = Load(() => import('../screen/Unit4/Error'))

const PurchaseInvoiceOverview = Load(() => import('../screen/PurchaseInvoice/Overview'));
const PurchaseInvoiceEdit = Load(() => import('../screen/PurchaseInvoice/Edit'));

const MetabaseEdit = Load(() => import('screen/Metabase/Edit'));
const MetabaseOverview = Load(() => import('screen/Metabase/Overview'));
const DashboardManagement = Load(() => import('screen/Dashboard/Management'));

const TruckPlanningExtendedOverview = Load(() => import('../screen/Planning/TruckPlanningOverview'));

const SurchargeOverview = Load(() => import('../screen/Surcharge/Overview'));
const SurchargeEdit = Load(() => import('../screen/Surcharge/Edit'));


export default class Router extends Component {
    static propTypes = {
        store: PropTypes.instanceOf(View).isRequired,
    };

    // react-router is a bit too verbose so I made a shorthand
    route = Screen => {
        return rProps => <Screen {...rProps} viewStore={this.props.store} />;
    };

    redirectFromHome = () => {
        const { currentUser } = this.props.store;
        let uri = currentUser.hasSuperpowers ? (
            '/account/user/overview'
        ) : (
            '/account/details'
        );

        return <Redirect to={uri} />;
    };

    render() {
        return (
            <Switch>
                <Route path="/" exact render={this.redirectFromHome} />

                <Route path="/admin/customer/overview" render={this.route(CustomerOverview)} />
                <Route path="/admin/customer/:id/edit" render={this.route(CustomerEdit)} />
                <Route path="/admin/customer/add" render={this.route(CustomerEdit)} />

                <Route path="/account/user/overview" render={this.route(UserOverview)} />
                <Route path="/account/user/:id/edit" render={this.route(UserEdit)} />
                <Route path="/account/user/add" render={this.route(UserEdit)} />
                <Route path="/account/user/email-log/overview" render={this.route(EmailLogOverview)} />

                <Route path="/assets/global-value/overview" render={this.route(GlobalValueOverview)} />
                <Route path="/assets/global-value/:id/edit" render={this.route(GlobalValueEdit)} />

                <Route path="/assets/global-file/overview" render={this.route(GlobalFileOverview)} />
                <Route path="/assets/global-file/:id/edit" render={this.route(GlobalFileEdit)} />

                <Route path="/audit/access-log/overview" render={this.route(AccessLogEntryOverview)} />

                <Route path="/account/details" render={this.route(AccountDetails)} />
                <Route path="/account/changelog" render={this.route(Changelog)} />
                <Route path="/account/integration-logger" render={this.route(IntegrationLoggerOverview)} />
                <Route path="/account/unit4" render={this.route(Unit4Overview)} />

                <Route path="/account/activity-status-email-template/overview" render={this.route(CommunicationActivityStatusEmailTemplateOverview)} />
                <Route path="/account/activity-status-email-template/:id/edit" render={this.route(CommunicationActivityStatusEmailTemplateEdit)} />
                <Route path="/account/activity-status-email-template/add" render={this.route(CommunicationActivityStatusEmailTemplateEdit)} />


                <Route path="/account/email-template/overview" render={this.route(CommunicationEmailTemplateOverview)} />
                <Route path="/account/email-template/:id/edit" render={this.route(CommunicationEmailTemplateEdit)} />
                <Route path="/account/email-template/add" render={this.route(CommunicationEmailTemplateEdit)} />

                <Route path="/login/forgot" render={this.route(LoginForgot)} />
                <Route path="/user/:id/reset-password/:token" render={this.route(ResetPassword)} />



                <Route path="/assets/trailer/overview" render={this.route(TrailerOverview)} />
                <Route path="/assets/trailer/add/trucking-company/:truckCompanyId" render={this.route(TrailerEdit)} />
                <Route path="/assets/trailer/add" render={this.route(TrailerEdit)} />
                <Route path="/assets/trailer/:id/edit" render={this.route(TrailerEdit)} />
                <Route path="/assets/trailer/fleet-number/:fleetNumber/edit" render={this.route(TrailerEdit)} />


                <Route path="/assets/truck/overview" render={this.route(TruckOverview)} />
                <Route path="/assets/truck/:id/edit" render={this.route(TruckEdit)} />
                <Route path="/assets/truck/fleet-number/:fleetNumber/edit" render={this.route(TruckEdit)} />
                <Route path="/assets/truck/add/trucking-company/:truckCompanyId" render={this.route(TruckEdit)} />
                <Route path="/assets/truck/add" render={this.route(TruckEdit)} />

                <Route path="/assets/district/overview" render={this.route(DistrictOverview)} />


                <Route path="/admin/service/overview" render={this.route(ServiceOverview)} />
                <Route path="/admin/service/:id/edit" render={this.route(ServiceEdit)} />
                <Route path="/admin/service/add/truck/:truckId" render={this.route(ServiceEdit)} />
                <Route path="/admin/service/add/trailer/:trailerId" render={this.route(ServiceEdit)} />
                <Route path="/admin/service/add" render={this.route(ServiceEdit)} />


                <Route path="/assets/service-type/overview" render={this.route(ServiceTypeOverview)} />
                <Route path="/assets/service-type/:id/edit" render={this.route(ServiceTypeEdit)} />
                <Route path="/assets/service-type/add" render={this.route(ServiceTypeEdit)} />

                <Route path="/assets/transporter/overview" render={this.route(TransporterOverview)} />
                <Route path="/assets/transporter/:id/edit" render={this.route(TransporterEdit)} />
                <Route path="/assets/transporter/add" render={this.route(TransporterEdit)} />

                <Route path="/assets/terminal/add/transporter/:transporterId" render={this.route(TerminalEditModal)} />

                <Route path="/assets/terminal-transporter/overview" render={this.route(TerminalTransporterOverview)} />
                <Route path="/assets/terminal-transporter/:id/edit" render={this.route(TerminalTransporterEdit)} />
                <Route path="/assets/terminal-transporter/add" render={this.route(TerminalTransporterEdit)} />

                <Route path="/assets/cost-calculation/overview" render={this.route(CostCalculationOverview)} />

                <Route path="/assets/trucking-company/overview" render={this.route(TruckingCompanyOverview)} />
                <Route path="/assets/trucking-company/:id/edit" render={this.route(TruckingCompanyEdit)} />
                <Route path="/assets/trucking-company/add" render={this.route(TruckingCompanyEdit)} />

                <Route path="/admin/trip/overview" render={this.route(TripOverview)} />
                <Route path="/admin/trip/:id/edit" render={this.route(TripEdit)} />
                <Route path="/admin/trip/add/:truckId/:trailerId/:activityType" render={this.route(TripEdit)} />
                <Route path="/admin/trip/add/truck/:truckId" render={this.route(TripEdit)} />
                <Route path="/admin/trip/add/trailer/:trailerId" render={this.route(TripEdit)} />
                <Route path="/admin/trip/add" render={this.route(TripEdit)} />

                <Route path="/assets/driver/overview" render={this.route(DriverOverview)} />
                <Route path="/assets/driver/:id/edit" render={this.route(DriverEdit)} />
                <Route path="/assets/driver/add" render={this.route(DriverEdit)} />

                <Route path="/account/task/overview" render={this.route(TaskOverview)} />
                <Route path="/account/task/:id/edit" render={this.route(TaskEdit)} />
                <Route path="/account/task/add" render={this.route(TaskEdit)} />

                <Route path="/admin/invoice/overview" render={this.route(InvoiceOverview)} />
                <Route path="/admin/invoice/:id/edit" render={this.route(InvoiceEdit)} />
                <Route path="/admin/invoice/number/:invoiceNumber/edit" render={this.route(InvoiceEdit)} />
                <Route path="/admin/invoice/add/order/:orderId/:inAdvance" render={this.route(InvoiceEdit)} />
                <Route path="/admin/invoice/add/order/:orderId" render={this.route(InvoiceEdit)} />
                <Route path="/admin/invoice/add" render={this.route(InvoiceEdit)} />

                <Route path="/admin/trucking-company-contract/overview" render={this.route(TruckingCompanyContractOverview)} />
                <Route path="/admin/trucking-company-contract/:id/edit" render={this.route(TruckingCompanyContractEdit)} />
                <Route path="/admin/trucking-company-contract/add/:truckingCompanyId" render={this.route(TruckingCompanyContractEdit)} />
                <Route path="/admin/trucking-company-contract/add" render={this.route(TruckingCompanyContractEdit)} />

                <Route path="/admin/surcharge/overview" render={this.route(SurchargeOverview)} />
                <Route path="/admin/surcharge/:id/edit" render={this.route(SurchargeEdit)} />

                <Route path="/admin/booking/overview" render={this.route(BookingOverview)} />
                <Route path="/admin/booking/:id/edit" render={this.route(BookingEdit)} />
                <Route path="/admin/booking/add" render={this.route(BookingEdit)} />

                <Route path="/admin/purchase-invoice/overview" render={this.route(PurchaseInvoiceOverview)} />
                <Route path="/admin/purchase-invoice/:id/edit" render={this.route(PurchaseInvoiceEdit)} />
                <Route path="/admin/purchase-invoice/add" render={this.route(PurchaseInvoiceEdit)} />

                <Route path="/admin/trip-activity/overview" render={this.route(TripActivityOverview)} />

                <Route path="/orders/overview" render={this.route(OrderOverview)} />

                {/* Deprecated only for backwards compatibility */}
                <Route path="/orders/entry" render={this.route(OrderEdit)} />

                <Route path="/orders/add" render={this.route(BulkOrderEdit)} />
                <Route path="/orders/:id/edit" render={this.route(OrderEdit)} />
                <Route path="/orders/number/:orderNumber/edit" render={this.route(OrderEdit)} />

                <Route path="/orders/forwarding/overview" render={this.route(OrderSubcontractOverview)} />
                <Route path="/orders/forwarding/:id/edit" render={this.route(OrderSubcontractEdit)} />
                <Route path="/orders/forwarding/add/order/:orderId" render={this.route(OrderSubcontractEdit)} />
                <Route path="/orders/forwarding/add" render={this.route(OrderSubcontractEdit)} />
                <Route path="/orders/forwarding" render={this.route(OrderSubcontractOverview)} />
                <Route path="/orders-bulk/:id/edit" render={this.route(BulkOrderEdit)} />

                <Route path="/forwarding/orders/overview" render={this.route(OrderSubcontractOverview)} />
                <Route path="/forwarding/orders/:id/edit" render={this.route(OrderSubcontractEdit)} />
                <Route path="/forwarding/orders/add/order/:orderId" render={this.route(OrderSubcontractEdit)} />
                <Route path="/forwarding/orders/add" render={this.route(OrderSubcontractEdit)} />
                {/* <Route path="/forwarding/screen" render={this.route(SubcontractingOverview)} /> */}

                <Route path="/sales-plan" render={this.route(SalesPlanOverview)} />
                <Route path="/forwarding" render={this.route(SubcontractingOverview)} />
                <Route path="/capacity-plan" render={this.route(CapacityPlanOverview)} />
                <Route path="/truck-planning" render={this.route(TruckPlanningOverview)} />
                <Route path="/truck-planning-extended" render={this.route(TruckPlanningExtendedOverview)} />

                <Route path="/orders" render={this.route(OrderOverview)} />
                <Route path="/admin" render={this.route(InvoiceOverview)} />
                <Route path="/data-configuration" render={this.route(TrailerOverview)} />

                <Route path="/unit4/ok" render={this.route(Unit4Ok)} />
                <Route path="/unit4/error/:error" render={this.route(Unit4Error)} />

                <Route path="/assets/metabase/overview" render={this.route(MetabaseOverview)} />
                <Route path="/assets/metabase/:id/edit" render={this.route(MetabaseEdit)} />
                <Route path="/assets/metabase/create" render={this.route(MetabaseEdit)} />
                <Route path={"/dashboard/overview/:id/view"} render={this.route(DashboardManagement)} />

                <Route path="/assets/goods-description/overview" render={this.route(GoodsDescriptionOverview)} />
                <Route path="/assets/goods-description/:id/edit" render={this.route(GoodsDescriptionEdit)} />
                <Route path="/assets/goods-description/add" render={this.route(GoodsDescriptionEdit)} />

                <Route render={this.route(NotFound)} />
            </Switch>
        );
    }
}
