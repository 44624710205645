import { Model, Store } from 'store/Base';
import { observable } from 'mobx';
import { Customer } from './Customer';

export const TYPE_INVOICE = 'invoice';
export const TYPE_SUBCONTRACT = 'subcontract';
export const TYPE_OPERATION = 'operation';
export const TYPE_UPDATE = 'update';

export class Contact extends Model {
    static backendResourceName = 'contact';

    @observable id = null;
    @observable type = '';
    @observable name = '';
    @observable emailAddress = '';

    relations() {
        return {
            customer: Customer,
        };
    }
}

export class ContactStore extends Store {
    Model = Contact;
    static backendResourceName = 'contact';
}
