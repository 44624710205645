import { observable } from 'mobx';
import { each, get, startCase } from 'lodash';
import { getMoneyInputProps, t } from '@code-yellow/spider';
import { isNumber } from 'underscore';
import { DateTime } from 'luxon';

export let TAB_TITLE_PREFIX = 'Octopus - '; // [TODO] this doesn't work -> process.env.REACT_APP_NAME + ' - ';
export let TAB_TITLE_SUFFIX = ' - Octopus'; // [TODO] this doesn't work -> process.env.REACT_APP_NAME + ' - ';
export let FRONTEND_API_BASE_URL = process.env.REACT_APP_CY_FRONTEND_API_BASE_URL || '/api/';

export const DEBOUNCE_WAIT = 200;
export const THROTTLE_WAIT = 1000;

// Config set by bootstrap.
export let MAPS_API_KEY = '';
export let MAPS_API_URL = '';
export const BUILD_INFO = observable({
    version: 'dev',
});
export const CURRENCY_RATE = 1.15;
export function calculatePrice(price, currency) {
    switch (currency) {
        case 'euro':
            return price * CURRENCY_RATE;
        case 'pound':
            return price / CURRENCY_RATE;
        default:
            return price;
    }
}


export function uuidv4() {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
        // eslint-disable-next-line no-mixed-operators
        (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    );
}


export function configOverride(bootstrap) {
    if (bootstrap.config_override !== undefined) {
        MAPS_API_KEY = bootstrap.config_override.GMAPS_CLIENT_KEY;
    } else {
        MAPS_API_KEY = bootstrap.google_maps_api_key;
    }
    MAPS_API_URL = bootstrap.google_maps_api_url;
    Object.assign(BUILD_INFO, bootstrap.build_info);
}

// Stolen from re-cy-cle
// lodash's `camelCase` method removes dots from the string; this breaks mobx-spine
export function snakeToCamel(s) {
    if (s.startsWith('_')) {
        return s;
    }
    return s.replace(/_\w/g, m => m[1].toUpperCase());
}

export function snakeToPascal(s) {
    return startCase(snakeToCamel(s)).replace(/ /g, '');
}

// lodash's `snakeCase` method removes dots from the string; this breaks mobx-spine
export function camelToSnake(s) {
    return s.replace(/([A-Z])/g, $1 => '_' + $1.toLowerCase());
}

// If we use a wysiwyg editor, it will encode the {{UUID}} tag.
export function useActualUuidTag(str) {
    return str.replace(/href="(([^"]*)%7B%7BUUID%7D%7D([^"]*))"/g, 'href="$2{{UUID}}$3"');
};

// TODO: make separate helper files categorized by theme, e.g. "money" and "date"
// This is insane at the moment, sorry man.

export const PUBLIC_URL =
    process.env.NODE_ENV !== 'production' ? process.env.PUBLIC_URL : '';

// While in debug mode, customer ids can be filtered here. It speeds up page
// loading and is automatically disabled on production to prevent goldplated ids
// going live.
export const ALLOCATION_IDS = [569, 290]; //[410, 414];

export const IS_DEBUG = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';

// Also used by mobile, which has a window, but no location?
export const IS_STAGE = typeof window !== 'undefined' && window.location && window.location.href.includes('staging');

// Feature flags
export const FLAG_ACTIVITY_ISSUES = IS_DEBUG || IS_STAGE;

export function floatToDecimal(value) {
    return value.toFixed(2).replace('.', ',');
}

// Stolen from https://gist.github.com/penguinboy/762197#gistcomment-2380871
const flatten = (object, prefix = '') => {
    return Object.keys(object).reduce((prev, element) => {
        return typeof object[element] === 'object'
            ? { ...prev, ...flatten(object[element], `${prefix}${element}.`) }
            : { ...prev, ...{ [`${prefix}${element}`]: object[element] } }
    }, {});
}

/**
 * Get list of error messages from the backend response. Typical usage:
 *
 * model.save().catch(response =>
 *     parseBackendErrorMessages(response.response.data.errors)
 * )
 */
export function parseBackendErrorMessages(errors) {
    const messages = [];
    const flat = flatten(errors);

    Object.keys(flat).forEach(key => {
        if (key.includes('.message')) {
            messages.push(flat[key]);
        }
    });

    return messages;
}

export function parseBackendErrorCodes(errors) {
    const codes = [];
    const flat = flatten(errors);

    Object.keys(flat).forEach(key => {
        if (key.includes('.code')) {
            codes.push(flat[key]);
        }
    });

    return codes;
}

export function decimalToFloat(value) {
    if (typeof value !== 'string') {
        return null;
    }
    return parseFloat(value.replace(/\./g, '').replace(',', '.'));
}

export const SCREEN_WIDTH_PX = '1280px';

export const SERVER_DATE_FORMAT = 'YYYY-MM-DD';
export const SERVER_DATETIME_FORMAT = `yyyy-LL-dd'T'HH:mm:ssZZZ`;
export const DATE_FORMAT = 'dd-MM-yyyy';
export const DATE_FORMAT_SHORT = 'dd-MM';
export const DATETIME_FORMAT = 'dd-MM-yyyy HH:mm';
export const DATETIME_FORMAT_PRETTY = 'ccc, LLL d, HH:mm';
export const DATETIME_SEC_FORMAT = 'dd-MM-yyyy HH:mm:ss';
export const DATETIME_FORMAT_SHORT = 'dd-MM HH:mm';
export const TIME_FORMAT = 'HH:mm';
export const DATE_RANGE_FORMAT = 'YYYY-MM-DD[T]HH:mm:ss';
export const ACTION_DELAY = 300;

export function formatDatetime(date, format) {
    let dateFormatted;
    format = format ?? DATE_FORMAT_SHORT;

    if (date?.toFormat) {
        dateFormatted = date?.toFormat(format);
    } else if (date == null) {
        dateFormatted = date;
    } else {
        dateFormatted = DateTime.fromISO(date).toFormat(format);
    }

    return dateFormatted;
}

export function setMoneyForBackend(value, decimals = 2) {
    if (typeof value !== 'string') {
        return 0;
    }

    const parsed = decimalToFloat(value);

    return isFinite(parsed) ? parsed * Math.pow(10, decimals) : 0;
}

const moneyFormatEuro = new Intl.NumberFormat('nl-NL', {
    style: 'currency',
    currency: 'EUR',
    currencyDisplay: 'symbol'
});

const moneyFormatPound = new Intl.NumberFormat('nl-NL', {
    style: 'currency',
    currency: 'GBP',
    currencyDisplay: 'symbol'
});



export function formatLuxonDate(maybeLuxon, noResult = '') {
    if (maybeLuxon) {
        return maybeLuxon.toFormat(DATE_FORMAT);
    }

    return noResult;
}

export function formatMoneyEuro(value, decimals = 2) {
    // Better money formatter, which prefixed the euro symbol. We're not yet
    // ready for this...
    //
    // There is a small but important difference with how MoneyInput formats
    // negative numbers. The negative sign must come first, and
    // Intl.NumberFormat sets the negative sign after the € sign.
    const formatted = moneyFormatEuro.format(value / Math.pow(10, decimals)).split(' ').join('');

    if (formatted.includes('-')) {
        return formatted[1] + formatted[0] + formatted.slice(2);
    }

    return formatted;
}

export function formatMoneyPound(value, decimals = 2) {
    // Better money formatter, which prefixed the euro symbol. We're not yet
    // ready for this...
    //
    // There is a small but important difference with how MoneyInput formats
    // negative numbers. The negative sign must come first, and
    // Intl.NumberFormat sets the negative sign after the € sign.
    const formatted = moneyFormatPound.format(value / Math.pow(10, decimals)).split(' ').join('');

    if (formatted.includes('-')) {
        return formatted[1] + formatted[0] + formatted.slice(2);
    }

    return formatted;
}

export function getMoneyForUser(value, decimals = 2) {
    if (typeof value !== 'number') {
        return null;
    }

    return (value / Math.pow(10, decimals)).toFixed(decimals).replace('.', ',');

}

// Found it weird to use money helpers on fuel surcharge, so simply
// wrap them.
export function setFuelSurchargeForBackend(value) {
    return setMoneyForBackend(value);
}

export function getFuelSurchargeForUser(value) {
    return getMoneyForUser(value);
}

export function setFactorForBackend(value) {
    return setMoneyForBackend(value);
}

export function getFactorForUser(value) {
    return getMoneyForUser(value);
}

export function getFactorInputProps() {
    return Object.assign(getMoneyInputProps(), {
        prefix: undefined,
        suffix: '%',
    });
}

// It is possible that in a <select> component, the currently selected model
// is not present in the list; either it is deleted, or the store has pagination, etc.
export function addSelectedModelInOptions(models, selectedModel) {
    const newModels = models.filter();
    if (selectedModel.id && !newModels.find(m => m.id === selectedModel.id)) {
        newModels.push(selectedModel);
    }
    return newModels;
}

// Accepts a request error, and transforms it into an array
// of notification messages.
export function formatCustomValidationErrors(err) {
    let output = [];

    each(get(err, 'response.data.errors'), (errors, resource) => {
        output = output.concat(
            errors["null"].written_at.map((e, i) => {
                return {
                    key: `${resource}${i}`,
                    message: e.message,
                    dismissAfter: 4000,
                };
            })
        );
    });
    return output;
}

export function formatPercentage(value) {
    if (!isNumber(value)) {
        value = 0;
    };

    return parseFloat(value).toFixed(1) + "%";
}

export const BOOL_OPTIONS = [
    { value: 'true', text: t('form.yes') },
    { value: undefined, text: t('form.either') },
    { value: 'false', text: t('form.no') },
];

// Stolen from mobx-spine/utils.js
// ['kind.breed', 'owner'] => { 'owner': {}, 'kind': {'breed': {}}}
export function relationsToNestedKeys(relations) {
    const nestedRelations = {};

    relations.forEach(rel => {
        let current = nestedRelations;
        const components = rel.split('.');
        const len = components.length;

        for (var i = 0; i < len; ++i) {
            const head = components[i];
            if (current[head] === undefined) {
                current[head] = {};
            }
            current = current[head];
        }
    });

    return nestedRelations;
}

// Stolen from mobx-spine/utils.js
// Use output of relationsToNestedKeys to iterate each relation, fn is called on each model and store.
export function forNestedRelations(model, nestedRelations, fn) {
    Object.keys(nestedRelations).forEach(key => {

        if (!model[key]) {
            //check if passed relation is defined in relations
            throw new Error(`Relation '${key}' is not defined in relations`);
        } else {
            if (Object.keys(nestedRelations[key]).length > 0) {
                if (model[key].forEach) {
                    model[key].forEach(m => {
                        forNestedRelations(m, nestedRelations[key], fn);
                    });

                    fn(model);
                } else {
                    forNestedRelations(model[key], nestedRelations[key], fn);
                }
            }

            if (model[key].forEach) {
                model[key].forEach(fn);
            }

            fn(model[key]);
        }

    });
}
