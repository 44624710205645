export const DRAFT = 'draft';
export const PLANNED = 'planned';
export const NEW = 'new';
export const REQUESTED = 'requested';
export const BOOKED = 'booked';
export const REJECTED = 'rejected';
export const CANCELLED = 'cancelled';

export const BOOKING_STATUSES = [DRAFT, PLANNED, NEW, REQUESTED, BOOKED, REJECTED, CANCELLED];

export default { DRAFT, PLANNED, NEW, REQUESTED, BOOKED, REJECTED, CANCELLED }
