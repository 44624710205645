import { Model, Store } from "store/Base";
import { action, observable, computed } from 'mobx';
import { TripStore } from "./Trip";
import { ServiceStore } from "./Service";
import { TruckingCompany } from "./TruckingCompany";
import { Location } from "./Location";
import { User } from "./User";
import { TransicsPosition } from "./TransicsPosition";
import ActivityType from "store/enums/ActivityType";
import { Truck } from "./Truck";
import { api } from 'store/Base';
import { showNotification } from "helpers/notification";
import { UserFlagsStore } from "./UserFlags";

export const OWNERS = ["Tip short term", "Tip long term"];

export class Trailer extends Model {
    static backendResourceName = "trailer";
    static omitFields = ['hasTasks'];

    @observable id = null;
    @observable licensePlate = "";
    @observable fleetNumber = "";
    @observable fleetCode = "";
    @observable dataSource = null;
    @observable dataReference = "";
    @observable equipment = "";
    @observable remarks = "";
    @observable inactive = true;
    @observable hasTasks = false;
    @observable serviceWarningLevel = null;

    get owned() {
        if (this.truckingCompany == null || this.truckingCompany.id == null) {
            return false;
        }

        return !this.truckingCompany?.subcontractor;
    }

    @computed get identificator() {
        return this.fleetNumber;
    }

    @computed get adr() {
        return this.attachedTruck?.adr
    }

    @computed get driver() {
        return this.attachedTruck?.driver
    }

    @computed get tagLabel() {
        return `${this.identificator}`;
    }

    @computed get editUrl() {
        return `/assets/trailer/${this.id}/edit`;
    }

    @computed get getColor() {
        if (this.id != null && this.inactive) {
            return "var(--red-100)";
        } else if (this.id != null) {
            return "var(--blue-50)"
        }
        return null;
    }

    @computed get colorBorder() {
        return this.truckingCompany?.colorCompany;
    }

    @computed get ownerName() {
        return this.truckingCompany?.name;
    }

    @computed get isConnectedToTransics() {
        return this.dataReference != null && this.dataReference !== "";
    }

    @computed get currentLocation() {
        let location = null;

        if (this.isConnectedToTransics && this.currentPosition?.id != null) {
            // [TODO] maybe should be switch toLocation function with geocode.
            location = new Location(this.currentPosition.toLocationClosest());
        } else if (this.location?.id != null) {
            location = this.location;
        }
        return location;
    }

    @computed get currentLocationString() {
        let location = this.currentLocation;

        if (location != null && location.city !== "") {
            return `${location.city}, ${location.country}`;
        } else {
            return t(`capacity.fields.unknown.label`);
        }
    }

    @computed get currentLocationInformation() {
        if (this.isConnectedToTransics && this.currentPosition?.id != null) {
            return this.currentPosition.toStringExtended();
        }

        return t('general.noExtraInformationAvailable');
    }

    @computed get hasEta() {
        return this.attachedTruck?.hasEta;
    }

    @computed get canEditEta() {
        if (this.attachedTruck == null || this.attachedTruck.id == null) {
            return false;
        }
        return this.attachedTruck?.canEditEta;
    }

    @computed get etaString() {
        if (this.attachedTruck == null || this.attachedTruck.id == null) {
            return '--';
        }
        return this.attachedTruck?.etaString;
    }

    @computed get etaInformation() {
        return this.attachedTruck?.etaInformation;
    }

    toggleActive() {
        this.inactive = !this.inactive;
    }

    async createYardPickUp(location) {
        await this.createYardTrips(location, ActivityType.YARD_PICK_UP)
    }

    async createYardDrop(location) {
        await this.createYardTrips(location, ActivityType.YARD_DROP)
    }

    async createYardTrips(location, type) {
        let locationObject = location;

        if (location?.id == null) {
            let locationObject = new Location({
                ...location.toJS(),
                point: JSON.stringify(location.point),
            });

            await locationObject.save();
        }

        await api.post(
            '/trailer/create-yard-trips/',
            { location: locationObject.id, type, trailer: this.id }
        )
    }

    async createTerminalTrips() {
        await api.post(
            '/trailer/create-terminal-trips/',
            { trailer: this.id }
        )
    }

    async getByFleetNumber(fleetNumber) {
        const trailerStore = new TrailerStore({
            params: {
                '.fleet_number': fleetNumber,
            },
            relations: this.__activeRelations,
        });

        await trailerStore.fetch();
        if (trailerStore.models.length > 0) {
            return trailerStore.models[0];
        } else {
            return null
        }
    }

    @action
    async checkCanDeactivate(id) {
        if (id > 0) {
            const response = await this.api.get("/trailer/" + id + "/can-deactivate/");
            return response.result;
        }

        return false;
    }

    activate = async () => {
        if (this.location.id > 0) {
            this.inactive = false;
            this.createYardPickUp(this.location);
            await this.save();
            return true;
        } else {
            showNotification(t(`trailer.edit.locationRequired`));
        }
    }

    deactivate = async () => {
        const canDeactivate = await this.checkCanDeactivate(this.id);

        if (canDeactivate) {
            this.inactive = true;
            this.save();
        } else {
            showNotification(t(`trailer.edit.cannotDeactivate`));

        }
    }

    hasWarnings() {
        return false;
    }

    relations() {
        return {
            assignedUser: User,
            trips: TripStore,
            services: ServiceStore,
            truckingCompany: TruckingCompany,
            location: Location,
            currentPosition: TransicsPosition,
            attachedTruck: Truck,
            userFlags: UserFlagsStore,
            recurrentServices: ServiceStore,
            nonRecurrentServices: ServiceStore,
        };
    }
}

export class TrailerStore extends Store {
    Model = Trailer;
    static backendResourceName = "trailer";
}
