import { Model, Store } from 'store/Base';
import { computed, observable } from 'mobx';
import { Location } from './Location';
import { Transporter } from './Transporter';

export class Terminal extends Model {
    static backendResourceName = 'terminal';

    @observable id = null;

    @observable name = '';
    @observable emailAddress = '';
    @observable phoneNumber = '';
    @observable costArea = '';

    @computed get address() {
        return this.location.address;
    };
    @computed get city() {
        return this.location.city;
    };
    @computed get country() {
        return this.location.country;
    };
    @computed get geoLocation() {
        return this.location.point;
    };

    relations() {
        return {
            transporter: Transporter,
            location: Location,
        };
    }
}

export class TerminalStore extends Store {
    Model = Terminal;
    static backendResourceName = 'terminal';
}
