import { Model, Store } from 'store/Base';
import { observable } from 'mobx';
import { Location } from './Location';
import { ServiceSubtype } from './ServiceSubtype';

export class ServiceSubtypeLocation extends Model {
    static backendResourceName = 'service_subtype_location';

    @observable id = null;
    @observable name = null;

    relations() {
        return {
            serviceSubtype: ServiceSubtype,
            location: Location,
        };
    }
}

export class ServiceSubtypeLocationStore extends Store {
    Model = ServiceSubtypeLocation;
    static backendResourceName = 'service_subtype_location';
}
