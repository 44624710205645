import { hideModal } from 'helpers/modal';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Modal } from 'semantic-ui-react';
import styled from 'styled-components';

export const CustomModal = styled(Modal)`
    border-radius: 8px !important;
    border-top: 8px solid var(--gray-50) !important;
    border-bottom: 8px solid var(--gray-300) !important;

    > div {
        height: 95vh;

        > section {
            // Fixing toolbar spacing due to extra border
            padding-top: 8px;
        }
    }

    > i {
        // Fixing close icon
        top: 4px !important;
    }
`


@observer
export default class ModalEditWrapper extends Component {
    static propTypes = {
        content: PropTypes.oneOfType([
            PropTypes.arrayOf(PropTypes.node),
            PropTypes.node
        ]),
        children: PropTypes.oneOfType([
            PropTypes.arrayOf(PropTypes.node),
            PropTypes.node
        ]),
    };

    render() {
        const { content, children, ...props } = this.props;
        return (
            <CustomModal data-test-truck-modal
                closeIcon
                open={true}
                size="fullscreen"
                onClose={() => hideModal()}
                {...props}
            >
                {content || children}
            </CustomModal>
        )
    }
}
