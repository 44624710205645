import React, { Component } from 'react';
import { observer } from 'mobx-react';
import PageTitle from './PageTitle';
import { Header } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

export const CustomHeader = styled(Header)`
    font-family: Lato  !important;
    font-style: normal  !important;
    font-weight: bold  !important;
    font-size: 24px !important;
    margin: 0px  !important;
    align-items: end !important;
    width: 100%;
    height: 100%;
`;

@observer
export default class PageHeader extends Component {
    static propTypes = {
        children: PropTypes.element.isRequired,
    };
    render() {
        return (
            <>
                <PageTitle title={this.props.children} />
                <CustomHeader as="h1">
                    {this.props.children}
                </CustomHeader>

            </>
        )
    }
};
