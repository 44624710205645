import React, { Component } from 'react';
import { Loader } from 're-cy-cle';
import styled from 'styled-components';

export const Dimmer = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;

    background-color: rgba(255,255,255,0.5);
    z-index: 1000;
`;

export const StyledPageLoader = styled.div`
    position: absolute;
    top: 50%;
    right: 50%;
    transform: translate(50%,-50%);
`;

export class PageLoader extends Component {
    render() {
        return (
            <Dimmer>
            <StyledPageLoader data-test-loader>
                <Loader height={50} {...this.props} />
            </StyledPageLoader>
            </Dimmer>
        );
    }
}
