import { observable } from 'mobx';
import { Model, Store } from 'store/Base';

export class Document extends Model {
    static backendResourceName = 'document_customer';

    @observable id = null;
    @observable file = null;
}

export class DocumentStore extends Store {
    Model = Document;
    static backendResourceName = 'document_customer';
}
