import { Model, Store } from 'store/Base';
import { observable } from 'mobx';
import { OrderSubcontract } from 'store/OrderSubcontract';

export class PurchaseInvoiceSubcontractOrderLine extends Model {
    static backendResourceName = 'purchase_invoice_subcontract_order_line';

    @observable id = null;
    @observable description = '';
    @observable amount = 0;

    relations() {
        return {
            orderSubcontract: OrderSubcontract,
        };
    }
}

export class PurchaseInvoiceSubcontractOrderLineStore extends Store {
    Model = PurchaseInvoiceSubcontractOrderLine;
    static backendResourceName = 'purchase_invoice_subcontract_order_line';
}
