import {
    showSaveNotification as baseShowSaveNotification,
    showDeleteNotification as baseShowDeleteNotification,
    showNotification as baseShowNotification,
    showErrorNotification as baseShowErrorNotification
} from '@code-yellow/spider'

export function showSaveNotification() {
    baseShowSaveNotification();
}

export function showDeleteNotification() {
    baseShowDeleteNotification();
}

export function showNotification(message) {
    if (typeof message === 'string') {
        baseShowNotification({ message });
    } else {
        baseShowNotification(message);
    }
}

export function showErrorNotification(message) {
    return baseShowNotification({
        error: true,
        message,
    });
}

export function showErrorNotifications(errors) {
    let errorType, errorId;
    try {
        for (errorType in errors) {
            for (errorId in errors[errorType]) {
                const messageCode = errors[errorType][errorId].code;
                if (!messageCode) {
                    showErrorNotifications(errors[errorType][errorId])
                } else {
                    baseShowErrorNotification(t(`error.${messageCode}`))
                }
            }
        }
    } catch (error) {
        console.log(error);
    }

}
