export const OPEN = 'open';
export const OVERDUE = 'overdue';
export const IN_PROGRESS = 'in_progress';
export const DONE = 'done';

export const TASK_STATUSES = [OPEN, OVERDUE, IN_PROGRESS, DONE];

export default { OPEN, OVERDUE, IN_PROGRESS, DONE }


export function getStatusColor(status) {
    switch (status) {
        case OPEN:
            return 'var(--purple-50)'
        case OVERDUE:
            return 'var(--red-50)'
        case IN_PROGRESS:
            return 'var(--orange-50)'
        case DONE:
            return 'var(--green-50)'
        default:
            return 'var(--gray-100)';
    }
}

export function getStatusBorderColor(status) {
    switch (status) {
        case OPEN:
            return 'var(--purple-600)'
        case OVERDUE:
            return 'var(--red-600)'
        case IN_PROGRESS:
            return 'var(--orange-600)'
        case DONE:
            return 'var(--green-600)'
        default:
            return 'var(--gray-100)';
    }
}