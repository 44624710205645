export const MONDAY = 'monday';
export const TUESDAY = 'tuesday';
export const WEDNESDAY = 'wednesday';
export const THURSDAY = 'thursday';
export const FRIDAY = 'friday';
export const SATURDAY = 'saturday';
export const SUNDAY = 'sunday';
export const DAYS = [MONDAY, TUESDAY, WEDNESDAY, THURSDAY, FRIDAY, SATURDAY, SUNDAY];

export const weekdayToNumber = (day) => {
    switch (day) {
        case MONDAY:
            return 1;
        case TUESDAY:
            return 2;
        case WEDNESDAY:
            return 3;
        case THURSDAY:
            return 4;
        case FRIDAY:
            return 5;
        case SATURDAY:
            return 6;
        case SUNDAY:
            return 7;
        default:
            return 0;
    }
}
export default { MONDAY, TUESDAY, WEDNESDAY, THURSDAY, FRIDAY, SATURDAY, SUNDAY }

