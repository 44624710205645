import { DRAFTED, SENT, PAID, OVERDUE, ERROR  } from "./InvoiceStatus";
import { DRAFT, PLANNED, ETA, ARRIVED, WAITING, STARTED, FINISHED, FINALIZED, CANCELED} from "./ActivityStatus";
import { STATUS_EXECUTING as EXECUTING} from "./TripStatus";
import { NEW, REQUESTED, BOOKED, REJECTED, CANCELLED  } from "./BookingStatus";
import { STATUS_PENDING as PENDING, STATUS_ACCEPTED as ACCEPTED, STATUS_ARCHIVED as ARCHIVED } from "store/PurchaseInvoice";
import { OPEN, IN_PROGRESS, DONE } from "./TaskStatus";

export function getStatusBackgroundColor(status, assetType) {
    assetType =  assetType ?? 'default';

    switch (status) {
        ///BLUE
        case NEW:
            if(assetType === 'trip') return 'var(--orange-50)'
            if(assetType === 'booking') return 'var(--orange-50)'
            return 'var(--blue-50)';
        case DRAFTED:
        case DRAFT:
            if(assetType === 'order_subcontract') return 'var(--orange-50)'
            if(assetType === 'activity') return 'var(--white)';
            return 'var(--blue-50)';

        ///ORANGE
        case PLANNED:
            if(assetType === 'trip') return 'var(--blue-50)'
            if(assetType === 'activity') return 'var(--white)';
            return 'var(--orange-50)';
        case EXECUTING:
        case REQUESTED:
        case IN_PROGRESS:
        case ETA: // = DRIVING
        case SENT:
            if(assetType === 'order_subcontract') return 'var(--green-50)'
            return 'var(--orange-50)';

        case ARRIVED:
            return 'var(--orange-100)';
            
        ///RED
        case REJECTED:
        case OVERDUE:
        case WAITING:
        case ERROR:
            return 'var(--red-50)'; 
        case CANCELLED: //trip acts.
        case CANCELED:
            return 'var(--red-100)'; 

        ///INDIGO
        case PENDING:
            return 'var(--indigo-50)';

        ///BLUEGREY
        case ARCHIVED:
            return 'var(--bluegrey-50)';

        ///PURPLE
        case OPEN:
        case FINISHED:
            return 'var(--purple-50)';

        ///GREEN
        case DONE:
        case PAID:
        case FINALIZED:
        case BOOKED:
        case ACCEPTED:
            return 'var(--green-50)'; 

        ///YELLOW
        case STARTED:
            return 'var(--yellow-100)'; 

        default:
            return 'var(--gray-50)';
    }
}

export function getStatusTextColor(status, assetType) {
    assetType =  assetType ?? 'default';

    switch (status) {
        ///BLUE
        case NEW:
            if(assetType === 'trip') return 'var(--orange-500)';
            if(assetType === 'booking') return 'var(--orange-500)';
            return 'var(--blue-500)';
        case DRAFTED:
        case DRAFT:
            if(assetType === 'order_subcontract') return 'var(--orange-500)';
            if(assetType === 'activity') return 'var(--black)';
            return 'var(--blue-500)';

        ///ORANGE
        case PLANNED:
            if(assetType === 'trip') return 'var(--blue-500)'
            if(assetType === 'activity') return 'var(--black)';
            return 'var(--orange-500)';
        case EXECUTING:
        case REQUESTED:
        case IN_PROGRESS:
        case ETA: // = DRIVING
        case SENT:
            if(assetType === 'order_subcontract') return 'var(--green-500)'
            return 'var(--orange-500)';
        case ARRIVED:
            return 'var(--orange-700)';

        ///RED
        case REJECTED:
        case OVERDUE:
        case WAITING:
        case ERROR:
            return 'var(--red-500)'; 
        case CANCELLED: //trip acts.
        case CANCELED:
            return 'var(--red-700)'; 

        ///INDIGO
        case PENDING:
            return 'var(--indigo-700)';

        ///BLUEGREY
        case ARCHIVED:
            return 'var(--bluegrey-700)';

        ///PURPLE
        case OPEN:
        case FINISHED:
            return 'var(--purple-500)';

        ///GREEN
        case DONE:
        case PAID:
        case FINALIZED:
        case BOOKED:
        case ACCEPTED:
            return 'var(--green-500)'; 

        ///YELLOW
        case STARTED:
            return 'var(--yellow-800)'; 
        default:
            return 'var(--gray-100)';
    }
}

export function getStatusColor(status) {
    switch (status) {
        case DRAFT:
            return 'var(--blue-50)';
        case PLANNED:
            return 'var(--orange-50)';
        case NEW:
            return 'var(--orange-100)';
        case REQUESTED:
            return 'var(--orange-200)';
        case BOOKED:
            return 'var(--green-50)';
        case REJECTED:
            return 'var(--red-200)';
        case CANCELLED:
            return 'var(--red-300)';
        default:
            return 'var(--gray-100)';
    }
}

// export function getStatusColorSemantic(status) {
//     switch (status) {
//         case DRAFT:
//             return 'blue';
//         case PLANNED:
//         case NEW:
//         case REQUESTED:
//             return 'orange';
//         case BOOKED:
//             return 'green';
//         case REJECTED:
//         case CANCELLED:
//             return 'red';
//         default:
//             return 'grey';
//     }
// }